import { AUTH_LOGOUT } from '../reducers/types';

const logoutSuccess = () => {
  const action = {
    type: AUTH_LOGOUT,
  };

  return action;
}

export { logoutSuccess };

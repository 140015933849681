import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import {
    UnorderedListOutlined,
    ShoppingOutlined,
    PieChartOutlined,
    DollarCircleOutlined,
    UserOutlined,
    SettingOutlined,
    TeamOutlined,
    SolutionOutlined,
    WalletOutlined,
    InboxOutlined,
    AreaChartOutlined,
    MenuFoldOutlined,
    PlusSquareOutlined,
    MailOutlined,
    LinkOutlined,
    CalendarOutlined,
    AppstoreOutlined,
    BellOutlined,
    ShoppingCartOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Avatar, Badge, Popover, Drawer, Button, Switch, Select } from 'antd';
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import { useSelector, useDispatch } from 'react-redux';
import userAvatar from '../../images/avatar.jpg';
import { useTranslation } from 'react-i18next';
import rockcrmLogo from '../../images/rockcrmLogo.svg';
import rockcrmText from '../../images/rockcrmText.svg';
import rockcrmLogoDark from '../../images/rockcrmLogoDark.svg';
import rockcrmTextDark from '../../images/rockcrmTextDark.svg';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, onClick) {
    return {
      key,
      icon,
    //   children,
      label,
      onClick,
    };
}

function LayoutContainer() {

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const {
      token: { colorBgContainer },
    } = theme.useToken();

    if (window.location.pathname === '/') {
        window.location.replace(window.location.href + 'dashboard');
    }

    const logout = () => {
        dispatch({type: 'SAGA_AUTH_LOGOUT'});
    }

    const changeTheme = (value) => {
        const theme = value ? 'dark' : 'white';
        dispatch({type: 'SAGA_CHANGE_THEME', theme});
    }

    const changeLang = (value) => {
        dispatch({type: 'SAGA_CHANGE_LANG', value});
    }

    let authName = useSelector((state) => state.auth.name);

    let themeSettings = useSelector((state) => state.theme);

    useEffect(() => {
        i18n.changeLanguage(themeSettings.lang)
    }, [themeSettings.lang])

    const items = [
        getItem(t('dashboard'), '1', <PieChartOutlined />, () => {navigate('/dashboard')}),
        getItem(t('orders'), '2', <UnorderedListOutlined />, () => {navigate('/orders')}),
        getItem(t('products'), '4', <ShoppingOutlined />, () => {navigate('/products')}),
        getItem(t('brands'), '3', <AppstoreOutlined />, () => {navigate('/brands')}),
        getItem(t('statistic'), '5', <AreaChartOutlined />, () => {navigate('/statistic')}),
        getItem(t('purchases'), '8', <PlusSquareOutlined />, () => {navigate('/purchases')}),
        getItem(t('expenses'), '9', <WalletOutlined />, () => {navigate('/expenses')}),
        // getItem(t('logs'), '10', <SolutionOutlined />, () => {navigate('/logs')}),
        // getItem(t('productPurchases'), '16', <ShoppingCartOutlined />, () => {navigate('/purchases')}),
        // getItem(t('npAccounts'), '12', <MailOutlined />, () => {navigate('/novaposhta/accounts')}),
        getItem(t('cashregisters'), '11', <DollarCircleOutlined />, () => {navigate('/cashregisters')}),
        getItem(t('stocks'), '7', <InboxOutlined />, () => {navigate('/stocks')}),
        getItem(t('sites'), '15', <LinkOutlined />, () => {navigate('/sites')}),
        getItem(t('clients'), '6', <UserOutlined />, () => {navigate('/clients')}),
        getItem(t('users'), '13', <TeamOutlined />, () => {navigate('/users')}),
        getItem(t('settings'), '14', <SettingOutlined />, () => {navigate('/settings')}),
    ];

    const pathArray = window.location.pathname.split('/');
    pathArray.shift();
    return (
        <Layout
        style={{
            minHeight: '100vh',
        }}
        >
        <Sider theme='light' collapsible collapsed={collapsed} trigger={null}>
            <div
                style={{
                    height: 32,
                    margin: 16,
                    display: 'flex',
                    gap: 5,
                    alignItems: 'center',
                    justifyContent: collapsed ? 'center' : 'initial'
                }}
            >
                <img src={themeSettings.theme === 'white' ? rockcrmLogo : rockcrmLogoDark} style={{ transition: 'all .3s ease', width: collapsed ? '35px' : '28px' }} />
                <img src={themeSettings.theme === 'white' ? rockcrmText : rockcrmTextDark} style={{ transition: 'all .3s ease', opacity: collapsed ? '0' : '1', position: collapsed ? 'absolute' : 'inherit' }} />
            </div>
            <Menu defaultSelectedKeys={['1']} mode="inline" items={items} />
        </Sider>
        <Layout className="site-layout">
            <Header
            style={{
                padding: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: colorBgContainer,
            }}
            >   
                <MenuFoldOutlined style={{ margin: '0 10px' }} onClick={() => {setCollapsed(!collapsed)}} />
                <div style={{
                    display: 'flex',

                    alignItems: 'center',
                    gap: '10px',
                }}>
                    <Popover placement="bottom" title="Help" content="Not today" trigger="click">
                        <QuestionCircleOutlined />
                    </Popover>
                    <Badge dot>
                        <CalendarOutlined />
                    </Badge>
                    <Badge size="small" count={2}>
                        <BellOutlined />
                    </Badge>
                    <Avatar
                        icon={<UserOutlined />}
                        onClick={() => {setOpenDrawer(true)}}
                        style={{
                        backgroundColor: '#262626',
                        margin: '0 25px 0 0',
                        }}
                    />
                </div>
            </Header>
            <Content
            style={{
                margin: '0 16px',
            }}
            >
            <Breadcrumb
                style={{
                margin: '16px 0',
                }}
            >
                <BreadcrumbItem>{t('home')}</BreadcrumbItem>
                {
                    pathArray.map((item, key) => 
                        <BreadcrumbItem key={key}>{t(item)}</BreadcrumbItem>
                    )
                }
            </Breadcrumb>
            <div
                style={{
                minHeight: 360,
                // background: colorBgContainer,
                }}
            >
                <Outlet />
            </div>
            </Content>
            <Drawer title="Basic Drawer" placement="right" onClose={() => {setOpenDrawer(false)}} open={openDrawer}>
                <div>
                    { authName }
                    <Avatar
                        icon={<UserOutlined />}
                        onClick={() => {setOpenDrawer(true)}}
                        style={{
                        backgroundColor: '#262626',
                        }}
                    />
                </div>
                <Switch defaultChecked={themeSettings.theme !== 'white' ? true : false} onChange={changeTheme}  />
                <Button danger type="primary" onClick={() => {logout()}}>Logout</Button>
                <Select
                    style={{
                        width: 120,
                    }}
                    defaultValue={themeSettings.lang}
                    onChange={value => {changeLang(value)}}
                    options={[
                        {
                            value: 'en',
                            label: 'English',
                        },
                        {
                            value: 'ru',
                            label: 'Russian',
                        },
                    ]}
                />
            </Drawer>
            <Footer
            style={{
                textAlign: 'center',
            }}
            >
            RockCRM ©2023 Created by StoneFaces
            </Footer>
        </Layout>
        </Layout>
    );
}

export default LayoutContainer;

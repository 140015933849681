import { PlusOutlined, SearchOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Table, Drawer, Form, Image, Divider, Row, InputNumber, Popconfirm, Col, Input, Space, Select } from 'antd';
import { useRef, useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import placeholder from '../../images/placeholder.jpg';
import get from "lodash.get";
import isequal from "lodash.isequal";
import moment from 'moment';

const { Option } = Select;

function PurchasesPage() {

  const formItemLayout = {
    labelCol: { span: 24 },
  }

  let themeSettings = useSelector((state) => state.theme);

  let { stocks } = useSelector((state) => state.auth);

  const [choosedProducts, setProduct] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [summary, setSummary] = useState([]);
  const [stocksData, setStocksData] = useState([]);
  const [purchasesData, setPurchasesData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [purchasesProducts, setPurchasesProducts] = useState([]);
  const [form] = Form.useForm();

  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const showDrawer = (type) => {
    if (type !== 'view') {
      setEditing(false);
      setPurchasesProducts([])
      setProduct([]);
      form.setFieldsValue({
        type: null,
        stock: null,
        comment: null
      });
    } else {
      setEditing(true)
    }
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
  });

  const onFinish = async (values) => {
    if (choosedProducts.length < 1) return;
    values.products = choosedProducts;
    await axiosInstance.post('create/purchase', values);  
    getPurchases();
    onClose();
  };

  const getProducts = async () => { 
    const products = await axiosInstance.post('get/products', { stocks });
    setProductsData(products.data.payload);
  }

  const getStocks = async () => {
    const stocks = await axiosInstance.get('get/stocks');
    setStocksData(stocks.data.payload)
  }
  
  const getPurchases = async () => {
    const result = await axiosInstance.post('get/purchases'); 
    setPurchasesData(result.data.payload)
  }

  const viewPurchase = (_id) => {
    const purchase = purchasesData.filter(item => item._id === _id)[0];

    const products = purchase.products.map(item => {
      const product = productsData.filter(product => item.product._id === product._id);

      console.log(item)

      return {
        _id: item.product._id,
        quantity: item.quantity,
        price: item.price,
        color: item.product.color,
        size: item.product.size,
        sku: item.product.sku,
        category: item.product.category,
        wholeSalePrice: product ? product[0].wholeSalePrice : '',
        names: item.product.names,
        images: item.product.images
      }
    })

    setPurchasesProducts(products)
    setProduct(products)
    form.setFieldsValue({
      type: purchase.type,
      stock: purchase.stock.name,
      comment: purchase.comment,
      products: products
    });

    showDrawer('view')
  }

  const addProduct = (_id, quantity = 0) => {

    const addProduct = productsData.find(item => item._id == _id)
    
    let added = false;

    let changedProducts;

    changedProducts = choosedProducts.map(item => {
      if (item._id === _id) {
        added = true
        console.log(quantity)

        if (quantity || quantity === null) {
          return { ...item, quantity };
        }

        return { ...item, quantity: item.quantity + 1 };
      } else {
        return item;
      }
    });

    setProduct(changedProducts);

    if (added) return;

    setProduct(current => [...current, { ...addProduct, quantity: 1 }]);
    
    // const products = [...choosedProducts];
    // const product = products.find(item => item._id === _id);
    // const addProduct = productsData.find(item => item._id === _id)[0];
    // console.log(addProduct)

    // if (product) {
    //   product.quantity += 1;
    //   setProduct(products);
    //   return;
    // }

    // setProduct(current => [...current, addProduct]);
  }

  const removeProduct = (_id) => {
    const products = choosedProducts.filter((item, index) => item._id !== _id);
    setProduct(products);
  }

  const changeQuantity = (value, _id) => {
    choosedProducts.forEach(item => {
      if (item._id === _id) item.quantity = value
    })
    productsSummary();
  }

  const productsSummary = () => {
      let sum = 0;
      choosedProducts.forEach(item => {
        sum += item.quantity * item.price
      })
      form.setFieldsValue({
        summary: sum,
        assessedValue: sum,
    });
    setSummary(sum);
  }

  const removePurchase = async (_id) => {
    await axiosInstance.post('remove/purchase', { _id });
    getPurchases();
  }


  useEffect(() => {
    form.setFieldsValue({
        products: choosedProducts,
    });
    productsSummary();
  }, [form, choosedProducts]);

  useEffect(() => {
    getProducts();
    getStocks();
    getPurchases();
  }, []);


  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      isequal(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const productColumns = [
    { 
      title: t('image'), 
      key: 'images',
      render: (text, { images }) => <div className="productImages">
        <Image.PreviewGroup>
          {
            images && images.length > 0 ?
            images.map((image, key) => 
              <Image key={key} className="productImage" width={70} src={process.env.REACT_APP_BACK_URL + 'uploads/' + image.url} />
            )
            :
            <Image className="productImage" width={70} src={placeholder} />
          }
        </Image.PreviewGroup>
      </div>
    },  
    { 
      title: t('name'),
      dataIndex: ['names', themeSettings.lang],
      key: 'names', 
      ...getColumnSearchProps(['names', themeSettings.lang]),
    },  
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.quantity - b.quantity,
      render: (text) => <>{ text } pcs.</>
    },
    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (text) => <>{ text } ₺</>
    },
    {
      title: t('wholeSalePrice'),
      dataIndex: 'wholeSalePrice',
      key: 'wholeSalePrice',
      render: (text) => <>{ text } ₺</>
    },
    {
      title: t('sku'),
      dataIndex: 'sku',
      key: 'sku',
      ...getColumnSearchProps('sku'),
    },
    {
      title: t('color'),
      dataIndex: ['color', 'name'],
      key: 'color',
      render: (text, { color }) => color ?
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>{text} <div className='colorCube' style={{backgroundColor: color.hex}}></div></div>
      :
      <></>
    },
    {
      title: t('size'),
      dataIndex: ['size', 'name'],
      key: 'size',
    },
    // {
    //   title: t('model'),
    //   dataIndex: 'model',
    //   key: 'model',
    // },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category',
    },
    {
      width: 30,
      render: (text, { _id }) => <Button style={{display: purchasesProducts.length > 0 ? 'none' : 'block'}}    onClick={() => addProduct(_id)} icon={<PlusOutlined />} />
    },
  ];

  const choosedProductColumns = [
    { 
      title: t('image'), 
      key: 'images',
      render: (text, { images }) => <div className="productImages">
        <Image.PreviewGroup>
          {
            images && images.length > 0 ?
            images.map((image, key) => 
              <Image key={key} className="productImage" width={70} src={process.env.REACT_APP_BACK_URL + 'uploads/' + image.url} />
            )
            :
            <Image className="productImage" width={70} src={placeholder} />
          }
        </Image.PreviewGroup>
      </div>
    },  
    { 
      title: t('name'),
      dataIndex: ['names', themeSettings.lang],
      key: 'names', 
      ...getColumnSearchProps(['names', themeSettings.lang]),
    },  
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.quantity - b.quantity,
      render: (text, {_id}) => <InputNumber onChange={(v) => {addProduct(_id, v)}} value={text} />
    },
    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (text) => <>{ text } ₺</>
    },
    {
      title: t('wholeSalePrice'),
      dataIndex: 'wholeSalePrice',
      key: 'wholeSalePrice',
      render: (text) => <>{ text } ₺</>
    },
    {
      title: t('sku'),
      dataIndex: 'sku',
      key: 'sku',
      ...getColumnSearchProps('sku'),
    },
    {
      title: t('color'),
      dataIndex: ['color', 'name'],
      key: 'color',
      render: (text, { color }) => color ?
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>{text} <div className='colorCube' style={{backgroundColor: color.hex}}></div></div>
      :
      <></>
    },
    {
      title: t('size'),
      dataIndex: ['size', 'name'],
      key: 'size',
    },
    // {
    //   title: t('model'),
    //   dataIndex: 'model',
    //   key: 'model',
    // },
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category',
    },
    {
      render: (text, { _id }) => <Button type="primary" disabled={editing} danger onClick={() => removeProduct(_id)} icon={<DeleteOutlined />} /> 
    }
  ];

  const purchasesColumns = [
    {
      title: t('type'),
      dataIndex: 'type',
      render: (text) => 
      <>{t(text)}</>
    },
    {
      title: t('stock'),
      dataIndex: ['stock', 'name'],
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
    },
    {
      title: t('created'),
      dataIndex: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      defaultSortOrder: 'descend',
      render: (text) => <>{moment(text).format("DD.MM.YYYY HH:mm:ss")}</>
    },
    {
      width: 30,
      render: (text, { _id }) => 
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <Button type="primary" icon={<EyeOutlined />}  onClick={() => {viewPurchase(_id)}} />
          <Popconfirm
            placement="leftBottom"
            title={t('wantToRemove')}
            onConfirm={() => removePurchase(_id)}
            okText={t('yes')}
            cancelText={t('no')}
            >
            <Button danger type="primary" icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
    }
  ];

  return (
    <>
      <Button style={{ float: 'right', margin: '5px' }} type="primary" onClick={showDrawer} icon={<PlusOutlined />} />

      <Table dataSource={purchasesData} columns={purchasesColumns} scroll={{x: 1300}} />
      <Drawer 
        title={t('create')}
        placement="right" 
        width={window.innerWidth > 900 ? window.innerWidth - 100 : window.innerWidth - 50}
        onClose={onClose} 
        open={open}
      >
        <Table columns={productColumns} dataSource={(purchasesProducts.length > 0 ? purchasesProducts : productsData).map((item, key) => ({
         _id: item._id,
         names: item.names,
         price: item.price,
         wholeSalePrice: item.wholeSalePrice,
         quantity: item.quantity,
         sku: item.sku,
         model: item.model,
         category: item.category,
         images: item.images,
         size: item.size,
         color: item.color,
         updatedAt: item.updatedAt,
         createdAt: item.createdAt,
          key,
        }))} scroll={{x: 1000, y: 300}}/>

        <Form
        form={form}
        layout='inline'
        onFinish={onFinish}
        initialValues={{products: choosedProducts}}
        {...formItemLayout}
      >  
        <Row>
        <Table style={{ display: purchasesProducts.length < 1 ? 'block' : 'none' }} columns={choosedProductColumns} dataSource={(choosedProducts).map((item, key) => ({
          _id: item._id,
          names: item.names,
          price: item.price,
          wholeSalePrice: item.wholeSalePrice,
          quantity: item.quantity,
          sku: item.sku,
          model: item.model,
          category: item.category,
          images: item.images,
          size: item.size,
          color: item.color,
          updatedAt: item.updatedAt,
          createdAt: item.createdAt,
            key,
          }))} scroll={{x: 1000, y: 300}}/>
          {/* <Col xs={{span: 24,}}sm={{span: 24,}} style={{padding: '0 10px', overflow: 'auto', display: purchasesProducts.length > 0 ? 'none' : 'block' }}>
            <Divider orientation="left" orientationMargin="0">{t('orderProducts')}</Divider>
            { choosedProducts.length === 0 ? 
              <div className="emptyProducts"><p>{t('empty')}</p></div> 
              : 
              choosedProducts.map((item, index) => 
                <Form.Item key={index}>
                  <div style={{borderBottom: '1px solid #cccccc', padding: '10px 0', display: 'flex', flexDirection: 'column', gap: 10}}>
                  <Form.Item label={t('name')} className="formItemPurchase">
                    <Input readOnly value={item.names[themeSettings.lang]} />
                    <Button type="primary" disabled={editing} danger onClick={() => removeProduct(index)} icon={<DeleteOutlined />} /> 
                  </Form.Item>
                    <Form.Item name={['products', index, '_id']} style={{position: 'absolute'}}>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name={['products', index, 'quantity']} label={t('quantity')}>
                      <InputNumber disabled={editing} onChange={value => {changeQuantity(value, item._id)}} min={1} max={100} style={{width: '70px'}} />
                    </Form.Item>
                    <Form.Item name={['products', index, 'price']} label={t('price')}>
                      <InputNumber min={1} addonAfter='₺' style={{width: '120px'}} />
                    </Form.Item>
                    <Form.Item name={['products', index, 'wholeSalePrice']} label={t('wholeSalePrice')}>
                      <InputNumber min={1} addonAfter='₺' style={{width: '120px'}} />
                    </Form.Item>
                  </div>
                </Form.Item>
              )
            }
          </Col> */}
          <Col xs={{span: 24,}}sm={{span: 24,}} style={{padding: '0 10px'}}> 
              <Form.Item name="type" label={t('type')} rules={[{ required: true, message: 'Please select type!' }]}>
                <Select 
                  disabled={editing}
                  options={[
                    {
                      label: t('add'),
                      value: 'add'
                    },
                    {
                      label: t('take'),
                      value: 'take'
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="stock" label={t('stock')} rules={[{ required: true, message: 'Please select stock!' }]}>
                <Select
                  disabled={editing} 
                  options={(stocksData || []).map((d) => ({
                      value: d._id,
                      label: d.name,
                    }))}
                />
              </Form.Item>
              <Form.Item name="comment" label={t('comment')}>
                <TextArea disabled={editing} />
              </Form.Item>
              <Form.Item style={{ marginTop: 10, display: editing ? 'none' : 'block' }}>
                <Button htmlType="submit" type="primary">{t('create')}</Button>
              </Form.Item> 
          </Col>
        </Row>
        </Form>

      </Drawer>
    </>
  )
}

export default PurchasesPage;
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Drawer, Form, Alert, Input, Space, Select } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import axios from 'axios';

function UsersPage() {
  
    const [open, setOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Error');
    const [usersData, setUsersData] = useState([]);
    const [editingUser, setEditingUser] = useState([]);
    const [sites, setSites] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [cashregisters, setCashregisters] = useState([]);

    const dispatch = useDispatch();

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACK_URL,
  });

    const onClose = () => {
        setOpen(false); 
    };

    const submitUserCreate = async (values) => {
      console.log(values)
        const { data } = await axiosInstance.post('create/user', values);
        console.log(data.status)
        if (data.status === 'ok') {
          setOpen(false);
          getUsers();
        } else if (data.status === 'failed name') {
          setAlertMessage('This site name is already taken');
          setAlertStatus(true);
          setTimeout(() => {setAlertStatus(false)}, 5000);
        } else if (data.status === 'failed login') {
          setAlertMessage('This site login is already taken');
          setAlertStatus(true);
          setTimeout(() => {setAlertStatus(false)}, 5000);
        }
    }

    const submitUserEdit = async (values) => {

      dispatch({type: 'SAGA_USER_EDIT', payload: values});
      const result = await axiosInstance.post('edit/user', { old: editingUser, new: values });
      setOpen(false);
      getUsers();
      console.log(result)
    }
    
    const getUsers = async () => {
      const users = await axiosInstance.get('get/users');
      console.log(users)
      setUsersData(users.data.payload);
    }

    const getSites = async () => {
      const sites = await axiosInstance.get('get/sites');
      setSites(sites.data.payload);
    }

    const getStocks = async () => {
      const stocks = await axiosInstance.get('get/stocks');
      setStocks(stocks.data.payload);
    }

    const getCashregisters = async () => {
      const cashregisters = await axiosInstance.get('get/cashregisters');
      setCashregisters(cashregisters.data.payload);
    }

    const removeUser = async (name) => {
      await axiosInstance.post('remove/user', { name });
      getUsers();
    }

    const [form] = Form.useForm();

    const showDrawer = () => {
      setEditingUser([])
      form.setFieldsValue({
        name: '',
        login: '',
        password: '',
        sites: [],
        stocks: [],
        cashregisters: [],
      });
      setOpen(true);
    };

    const editUser = async (name) => {
      const user = usersData.find((item) => item.name === name);
      user.sites = user.sites.map(item => item._id);
      user.stocks = user.stocks.map(item => item._id);
      user.cashregisters = user.cashregisters.map(item => item._id);

      setEditingUser(user);
      setOpen(true);
    }

    useEffect(() => {
      getUsers();
      getSites();
      getStocks();
      getCashregisters();
    }, [])
    
    useEffect(() => {
      console.log(editingUser.name);
      form.setFieldsValue({
          name: editingUser.name,
          login: editingUser.login,
          password: editingUser.password,
          sites: editingUser.sites,
          stocks: editingUser.stocks,
          cashregisters: editingUser.cashregisters,
          role: editingUser.role,
      });
    }, [form, editingUser]);

    
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Login',
        dataIndex: 'login',
        key: 'login',
      },
      {
        title: 'Password',
        key: 'password',
        render: () => <>***********</>
      },
      { 
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        width: 30,
        render: (text, { name }) => 
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Button onClick={() => removeUser(name)} danger type="primary" icon={<DeleteOutlined />} />
            <Button onClick={() => editUser(name)} icon={<EditOutlined />} />
          </div>
        
      },
    ];


    return (
        <>
      <Button style={{float: 'right', margin: '5px'}} type="primary" onClick={showDrawer} icon={<PlusOutlined />} />

      <Table dataSource={usersData} columns={columns} />


      <Drawer
        title={ editingUser.length !== 0 ? "Edit a user" : "Create a new user" }
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Form layout="vertical" onFinish={ editingUser.length !== 0 ? submitUserEdit : submitUserCreate } form={form}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter user name',
                  },
                ]}
              >
                <Input placeholder="Please enter user login" />
              </Form.Item>
              <Form.Item
                name="login"
                label="Login"
                rules={[
                  {
                    required: true,
                    message: 'Please enter login',
                  },
                ]}
              >
                <Input
                  style={{
                    width: '100%',
                  }}
                  placeholder="Please enter login"
                />
              </Form.Item> 
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter password',
                  },
                ]}
              >
                <Input
                  style={{
                    width: '100%',
                  }}
                  placeholder="Please enter password"
                />
              </Form.Item>  
              <Form.Item label="Account Role" name="role">
                <Select 
                  placeholder="Roles"
                  options={[
                    {
                      value: 'manager',
                      label: 'Manager'
                    },
                    {
                      value: 'director',
                      label: 'Director'
                    },
                    {
                      value: 'administrator',
                      label: 'Administrator'
                    }
                  ]} 
                />
              </Form.Item>
              <Form.Item label="Sites" name="sites">
                <Select 
                  mode="multiple"
                  placeholder="Sites"
                  options={(sites || []).map((d) => ({
                      value: d._id,
                      label: d.name,
                  }))} 
                />
              </Form.Item>
              <Form.Item label="Stocks" name="stocks">
                <Select 
                  mode="multiple"
                  placeholder="Stocks"
                  options={(stocks || []).map((d) => ({
                      value: d._id,
                      label: d.name,
                  }))} 
                />
              </Form.Item>
              <Form.Item label="Cashregisters" name="cashregisters">
                <Select 
                  mode="multiple"
                  placeholder="Cashregisters"
                  options={(cashregisters || []).map((d) => ({
                      value: d._id,
                      label: d.name,
                  }))} 
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">{ editingUser.length !== 0 ? "Edit" : "Create"}</Button>
              </Form.Item>
              <Alert
                message="Error"
                description={alertMessage}
                type="error"
                showIcon
                style={{display: alertStatus ? 'block' : 'none'}}
              />
        </Form>
      </Drawer>
    </>
    )
}

export default UsersPage
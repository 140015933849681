import { Routes, Route } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect } from "react"; 
import { ConfigProvider, theme } from 'antd';

// CONTAINERS

import Layout from '../containers/Layout';
import StocksPage from '../containers/StocksPage';
import SitesPage from '../containers/SitesPage';
import PageNotFound from '../containers/PageNotFound';
import DashboardPage from '../containers/DashboardPage';
import ProductsPage from '../containers/ProductsPage';
import OrdersPage from '../containers/OrdersPage';
import SettingsPage from '../containers/SettingsPage';
import NpAccountsPage from '../containers/NpAccountsPage';
import CashregistersPage from '../containers/CashregistersPage';
import PurchasesPage from '../containers/PurchasesPage';
import LoginPage from '../containers/LoginPage';
import UsersPage from '../containers/UsersPage';
import StatisticPage from '../containers/StatisticPage';
import ExpensesPage from '../containers/ExpensesPage';
import ClientsPage from '../containers/ClientsPage';
import CategoriesPage from '../containers/CategoriesPage';

import ViewOrderPage from '../containers/ViewOrderPage';
import EditOrderPage from '../containers/EditOrderPage';

import CreateOrderPage from '../containers/CreateOrderPage';
// import CreateSiteForm from '../containers/CreateSiteForm';
// import CreateCashRegisterForm from '../containers/CreateCashRegisterForm';
// import CreateStockForm from '../containers/CreateStockForm';
import AcceptProductsPage from '../containers/AcceptProductsPage';

function App() {

  let authStatus = useSelector((state) => state.auth.status);

  let interfaceTheme = useSelector((state) => state.theme.theme);

  return (
    <ConfigProvider theme={{
      token: {
        "colorPrimary": "#722ED1",
        "fontSize": 12,
        "borderRadius": 5,
        "wireframe": true,
      },
      algorithm: interfaceTheme === 'dark' ? theme.darkAlgorithm : null,
    }}>
      <Routes>
        { authStatus === 'auth' ? (
          <Route path="/" element={<Layout />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/stocks" element={<StocksPage />} />
            <Route path="/cashregisters" element={<CashregistersPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/brands" element={<CategoriesPage />} />
            <Route path="/statistic" element={<StatisticPage />} />
            <Route path="/novaposhta/accounts" element={<NpAccountsPage />} />
            <Route path="/clients" element={<ClientsPage />} />
            <Route path="/purchases" element={<PurchasesPage />} />
            <Route path="/expenses" element={<ExpensesPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/logs" element={<h1>Logs</h1>} />
            <Route path="/sites" element={<SitesPage />} />
            <Route path="/settings" element={<SettingsPage />} />

            <Route path="/view/order/:id" element={<ViewOrderPage />} />
            <Route path="/edit/order/:id" element={<EditOrderPage />} />

            <Route path="/accept/products" element={<AcceptProductsPage />} />
            <Route path="/create/order" element={<CreateOrderPage />} />
            {/* <Route path="/create/site" element={<CreateSiteForm />} />
            <Route path="/create/cashregister" element={<CreateCashRegisterForm />} />
            <Route path="/create/stock" element={<CreateStockForm />} /> */}
            <Route path="*" element={<PageNotFound loginStatus="auth" />} />
          </Route>
        ) : (
          <>
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<PageNotFound loginStatus="guest" />} />
          </>
        ) }
      
      </Routes>
    </ConfigProvider>
  );
}

export default App;
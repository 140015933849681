import {
    AUTH_IN_PROGRESS,
    AUTH_SUCCESS,
    AUTH_FAILED,
    AUTH_UPDATE_TOKENS,
    USER_EDIT_SUCCESS,
} from '../reducers/types';

  
  const authInProgress = () => {
    const action = {
      type: AUTH_IN_PROGRESS,
    };
  
    return action;
  }
  
  const authSuccess = (id, name, login, tokens, sites, stocks, cashregisters) => {
    const action = {
      type: AUTH_SUCCESS,
      payload: { id, name, login, tokens, sites, stocks, cashregisters }
    };
  
    return action;
  }

  const userEditSuccess = (id, name, login, tokens, sites, stocks, cashregisters) => {
    const action = {
      type: USER_EDIT_SUCCESS,
      payload: { id, name, login, tokens, sites, stocks, cashregisters }
    };
  
    return action;
  }
  
  const authFailed = () => {
    const action = {
      type: AUTH_FAILED,
    };
  
    return action;
  }
  
  export { authSuccess, authFailed, authInProgress, userEditSuccess };
export const APP_SET_TITLE = 'APP_SET_TITLE';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const AUTH_UPDATE_TOKENS = 'AUTH_UPDATE_TOKENS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const CHANGE_THEME = 'CHANGE_THEME';

export const CHANGE_LANG = 'CHANGE_LANG';

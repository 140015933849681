import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, Drawer, Form, Alert, Input, Space } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function SitesPage() {
  
    const [open, setOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Error');
    const [categories, setCategories] = useState([]);
    const [editingCategory, setEditingCategory] = useState([]);
    const { t, i18n } = useTranslation();


    const onClose = () => {
        setOpen(false);
    };

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACK_URL,
    });

    const submitCategoryCreate = async (values) => {
        const { data } = await axiosInstance.post('create/category', values);
        if (data.status === 'ok') {
          setOpen(false);
          getCategories();
        } else if (data.status === 'failed name') {
          setAlertMessage('This site name is already taken');
          setAlertStatus(true);
          setTimeout(() => {setAlertStatus(false)}, 5000);
        } else if (data.status === 'failed domain') {
          setAlertMessage('This site domain is already taken');
          setAlertStatus(true);
          setTimeout(() => {setAlertStatus(false)}, 5000);
        }
          
    }

    const submitCategoryEdit = async (values) => {
      await axiosInstance.post('edit/category', { old: editingCategory, new: values });
      setOpen(false);
      getCategories();
    }
    
    const getCategories = async () => {
      const categories = await axiosInstance.get('get/categories');
      setCategories(categories.data.payload);
    }

    const removeCategory = async (_id) => {
      await axiosInstance.post('remove/category', { _id });
      getCategories();
    }

    const [form] = Form.useForm();

    const showDrawer = () => {
      setEditingCategory([])
      form.setFieldsValue({
        name: '',
      });
      setOpen(true);
    };

    const editCategory = async (_id) => {
      setEditingCategory(categories.find((item) => item._id === _id));
      setOpen(true);
    }

    useEffect(() => {
        getCategories();
    }, [])
    
    useEffect(() => {
      form.setFieldsValue({
          name: editingCategory.name,
      });
    }, [form, editingCategory]);

    
    const columns = [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: t('updated'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
      },
      {
        title: t('created'),
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        width: 30,
        render: (text, { _id }) => 
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Button onClick={() => removeCategory(_id)} danger type="primary" icon={<DeleteOutlined />} />
            <Button onClick={() => editCategory(_id)} icon={<EditOutlined />} />
          </div>
        
      },
    ];


    return (
        <>
        <Button style={{ float: 'right', margin: '5px' }} type="primary" onClick={showDrawer} icon={<PlusOutlined />} />

      <Table 
        dataSource={categories} 
        columns={columns}
        scroll={{
          x: 1300,
        }}
      />


      <Drawer
        title={ editingCategory.length !== 0 ? t('edit') : t('create') }
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>{t('cancel')}</Button>
          </Space>
        }
      >
        <Form layout="vertical" onFinish={ editingCategory.length !== 0 ? submitCategoryEdit : submitCategoryCreate } form={form}>
              <Form.Item
                name="name"
                label={t('name')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter user name',
                  },
                ]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>  
              <Form.Item>
                <Button htmlType="submit" type="primary">{ editingCategory.length !== 0 ? t('edit') : t('create')}</Button>
              </Form.Item>
              <Alert
                message="Error"
                description={alertMessage}
                type="error"
                showIcon
                style={{display: alertStatus ? 'block' : 'none'}}
              />
        </Form>
      </Drawer>
    </>
    )
}

export default SitesPage
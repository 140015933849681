import { Form, Input, Button, Select, Divider, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function OrderInfoForm (props) {

    const [cities, setCities] = useState([]);
    const [offices, setOffices] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [officeOptions, setOfficeOptions] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [sites, setSites] = useState([]);
    const [stocks, setStocks] = useState([]); 
    const [npAccounts, setNpAccounts] = useState([]);
    const [deliveryType, setDeliveryType] = useState(props.deliveryType);
    const [postomat, setPostomat] = useState(false);
    const [hideExtra, setHideExtra] = useState(false);
    const [clientPaid, setClientPaid] = useState(0);

    const { t, i18n } = useTranslation();

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BACK_URL,
    });

    const getNovaposhtaData = async () => {
        const { data } = await axiosInstance.get('get/novaposhta');
        setCities(data.payload.cities);
        setOffices(data.payload.offices);
    };

    const getSites = async () => {
        const { data } = await axiosInstance.get('get/sites');
        setSites(data.payload)
    }

    const getStocks = async () => {
        const { data } = await axiosInstance.get('get/stocks');
        setStocks(data.payload)
    }

    const getNpAccounts = async () => {
        const { data } = await axiosInstance.get('get/novaposhta/accounts');
        setNpAccounts(data.payload);
      }

    const onChangeCity = (value) => {
        setSelectedCity(value)
    };

    const onChangePostOffice = (value) => {
        const result = offices.filter(item => item.Ref === value);
        if (result[0].CategoryOfWarehouse === 'Postomat') {
            setPostomat(true);
        } else {
            setPostomat(false);
        }
    };

    const onSearchCity = (value) => {
        const results = cities.filter(item => item.Description.search(value) !== -1);
        setCityOptions(results)
    };

    const onSearchOffice = (value) => {
        const results = offices.filter(item => item.Description.search(value) !== -1);
        const filtred = results.filter(item => item.SettlementRef === selectedCity);

        setOfficeOptions(filtred)
    };

    useEffect(() => {
        getNovaposhtaData();
        getSites();
        getStocks();
        getNpAccounts();
    }, []);

    useEffect(() => {
        setDeliveryType(props.deliveryType);
        props.form.setFieldsValue({
            change: clientPaid - props.summary,
        })    
    }, [props.deliveryType, props.form, props.summary, clientPaid])
    
    return (
        <>
            <Divider orientation="left" orientationMargin="0">{t('orderInfo')}</Divider>
            <div className='oneFourForm'>
                <Form.Item name="site" label={t('sites')}>
                    <Select 
                        placeholder="Site"
                        options={(sites || []).map((d) => ({
                            value: d._id,
                            label: d.name,
                          }))} 
                    />
                </Form.Item>
                <Form.Item name="stock" label={t('stocks')}>
                <Select 
                        placeholder="Stocks"
                        options={(stocks || []).map((d) => ({
                            value: d._id,
                            label: d.name,
                          }))} 
                    />
                </Form.Item>
                <Form.Item name="deliveryType" label={t('deliveryType')}>
                    <Select onChange={(value) => {setDeliveryType(value)}}>
                        <Option value="selfpickup">{t('selfpickup')}</Option>
                        <Option value="courierDelivery">{t('courierDelviery')}</Option>
                        <Option value="novaposhtaDelivery">{t('novaposhtaDelivery')}</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="paymentType" label={t('paymentType')}>
                    <Select placeholder="Payment Type">
                        <Option value="cash">{t('cash')}</Option>
                        <Option value="card">{t('card')}</Option>
                    </Select>
                </Form.Item>
            </div>
            <div className="oneTwoForm">
                <Form.Item name="orderStatus" label={t('orderStatus')}>
                    <Select placeholder="Order Status">
                        <Option value="notConfirmed">{t('notConfirmed')}</Option>
                        <Option value="confirmed">{t('confirmed')}</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="paymentStatus" label="Payment Status">
                    <Select placeholder="Payment Status">
                        <Option value="paid">{t('paid')}</Option>
                        <Option value="notPaid">{t('notPaid')}</Option>
                    </Select>
                </Form.Item>
            </div>
            <Divider orientation="left" orientationMargin="0" style={{display: deliveryType !== 'selfpickup' ? 'flex' : 'none'}}>{t('orderDelivery')}</Divider>
            <div style={{display: deliveryType === 'courierDelivery' ? 'block' : 'none'}}>
                <Form.Item name="address" label="Address">
                    <Input placeholder="Address" />
                </Form.Item>
            </div>
            <div style={{display: deliveryType === 'novaposhtaDelivery' ? 'block' : 'none'}}>
                <div className='oneFourForm'>
                    <Form.Item name="npAccount" label="NP account">
                    <Select 
                            placeholder="NP Accounts"
                            options={(npAccounts || []).map((d) => ({
                                value: d._id,
                                label: d.name,
                            }))} 
                        />
                    </Form.Item>
                    <Form.Item name="weight" label="Wight (kg.)" style={{ display: !hideExtra ? 'block' : 'none' }}>
                        <Input placeholder="Wight (kg.)" />
                    </Form.Item>
                    <Form.Item name="assessedValue" label="Assessed value">
                        <Input placeholder="Assessed value" />
                    </Form.Item>
                    <Form.Item name="ttn" label="TTN">
                        <Input placeholder="TTN" onChange={e => {
                            if (e.target.value.length > 0) {
                                setHideExtra(true);
                            } else {
                                setHideExtra(false);
                            }
                        }} />
                    </Form.Item>
                </div>
                <div className='oneFourForm' style={{ display: !hideExtra ? 'grid' : 'none' }}>
                    <Form.Item name="deliveryPayer" label="Delivery Payer">
                        <Select placeholder="Delivery Payer">
                            <Option value="Recipient">Recipient</Option>
                            <Option value="Sender">Sender</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="parcelWidth" label="Parcel Width" style={{ display: postomat ? 'block' : 'none' }}>
                        <Input placeholder="Parcel Width" />
                    </Form.Item>
                    <Form.Item name="parcelHeight" label="Parcel Height" style={{ display: postomat ? 'block' : 'none' }}>
                        <Input placeholder="Parcel Height" />
                    </Form.Item>
                    <Form.Item name="parcelLength" label="Parcel Length" style={{ display: postomat ? 'block' : 'none' }}>
                        <Input placeholder="Parcel Length" />
                    </Form.Item>
                </div>
                <div className="oneTwoForm" style={{ display: !hideExtra ? 'grid' : 'none' }}>
                    <Form.Item name="city" label="City">
                    <Select
                        showSearch
                        placeholder="Select a city"
                        optionFilterProp="children"
                        onChange={onChangeCity}
                        onSearch={onSearchCity}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={(cityOptions || []).map((d) => ({
                            value: d.Ref,
                            label: d.Description,
                        }))}
                    />
                    </Form.Item>
                    <Form.Item name="postOffice" label="Post office">
                    <Select
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        onSearch={onSearchOffice}
                        onChange={onChangePostOffice}
                        filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={(officeOptions || []).map((d) => ({
                            value: d.Ref,
                            label: d.Description,
                        }))}
                    />
                    </Form.Item>
                </div>
            </div>
            <Divider orientation="left" orientationMargin="0">{t('clientInfo')}</Divider>
            <div className='oneFourForm'>
                <Form.Item name="fullname" label={t('fullname')} style={{ display: !hideExtra ? 'grid' : 'none' }}>
                    <Input placeholder="Full name" />
                </Form.Item>
                <Form.Item name="telephone" label={t('phone')} style={{ display: !hideExtra ? 'grid' : 'none' }}>
                    <Input placeholder="Telephone" />
                </Form.Item>
                <Form.Item name="email" label={t('email')}>
                    <Input placeholder="Email" />
                </Form.Item>
                {/* <Form.Item name="discount" label={t('')}>
                    <Select placeholder="Discount">
                        <Option value="0">0%</Option>
                        <Option value="1">1%</Option>
                        <Option value="2">2%</Option>
                        <Option value="3">3%</Option>
                        <Option value="4">4%</Option>
                        <Option value="5">5%</Option>
                    </Select>
                </Form.Item> */}
            </div>
            <Form.Item name="comment" label={t('comment')} style={{width: '100%'}}>
                <Input.TextArea allowClear showCount />
            </Form.Item>
            <div className='oneThreeForm'>
                <Form.Item name="summary" label={t('summary')}>
                    <InputNumber placeholder="Summary" disabled addonAfter='₴' />
                </Form.Item>
                <Form.Item name="clientPaid" label={t('clientPaid')}>
                    <InputNumber placeholder="Client Paid" onChange={(value) => {setClientPaid(value)}} addonAfter='₴' />
                </Form.Item>
                <Form.Item name="change" label={t('change')}>
                    <InputNumber placeholder="Change" disabled addonAfter='₴' />
                </Form.Item>
            </div>
            <Form.Item style={{margin: '15px 0'}}>
                <Button type="primary" htmlType="submit" disabled={false}>
                    {t('back')}
                </Button>
            </Form.Item>
        </>
    );
}

export default OrderInfoForm;
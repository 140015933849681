import { useNavigate } from "react-router-dom";
import { Button, Result } from 'antd';

function PageNotFound(props) {
    
    const navigate = useNavigate();

 
    return (
        <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button onClick={() => { props.loginStatus === 'auth' ? navigate(-1) : navigate('/') }} type="primary">Back Home</Button>}
      />
    )
}

export default PageNotFound;
import { takeLatest } from 'redux-saga/effects';
import { put, call } from 'redux-saga/effects';
import axios from 'axios';

// ACTIONS

import {  authSuccess, authFailed, authInProgress, userEditSuccess } from '../actions/auth';

import { logoutSuccess } from '../actions/logout';

import { changeThemeSuccess, changeLangSuccess } from '../actions/theme';

// import { requestProductsLoading, requestProductsSuccess, requestProductsFailed } from '../actions/loading';

// SAGA LIST

function* login(action) {
  
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACK_URL,
    });
  
    yield put(authInProgress());
    
    const result = yield call(() => axiosInstance.post('login/user', action.payload));

    if (result.data.status === 'ok') {
      const { id, name, login, tokens, sites, stocks, cashregisters } = result.data.payload;
     
      yield put(authSuccess(id, name, login, tokens, sites, stocks, cashregisters));
    } else {
      yield put(authFailed());
    }

    return action;
}

function* logout() {
  yield put(logoutSuccess());
}

function* changeTheme(action) {
  yield put(changeThemeSuccess(action.theme));
}

function* changeLang(action) {
  yield put(changeLangSuccess(action.value));
}

function* editUser(action) {

  const { id, name, login, tokens, sites, stocks, cashregisters } = action.payload;

  stocks = stocks.map(item => ({
    _id: item
  }))
  
  cashregisters = cashregisters.map(item => ({
    _id: item
  }))

  sites = sites.map(item => ({
    _id: item
  }))

  yield put(userEditSuccess(id, name, login, tokens, sites, stocks, cashregisters));
}
 
// function* requestProducts() {
//   yield put(requestProductsLoading());
  
//   const axiosInstance = axios.create({
//     baseURL: process.env.REACT_APP_BACK_URL,
//   });
  
//   const { data } = yield call(() => axiosInstance.post('request/products', action.payload));

//   if (data.status === 'ok') {
//     yield put(requestProductsSuccess());
//   } else {
//     yield put(requestProductsFailed());
//   }
// }


//SAGA RUN

function* mySaga() {
    yield takeLatest("SAGA_AUTH_LOGOUT", logout);
    yield takeLatest("SAGA_LOGIN_REQUEST", login);
    yield takeLatest("SAGA_CHANGE_THEME", changeTheme);
    yield takeLatest("SAGA_CHANGE_LANG", changeLang);
    yield takeLatest("SAGA_USER_EDIT", editUser);
    // yield takeLatest("SAGA_REQUEST_PRODUCTS", requestProducts);
}

export default mySaga;

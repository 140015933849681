import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux'; 
import loginPageBackground from '../../images/loginPage.webp'

const LoginPage = () => {

    const dispatch = useDispatch();


    const onFinish = (values) => {
        console.log(values);
        dispatch({type: 'SAGA_LOGIN_REQUEST', payload: values});
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img style={{width: '100%', objectFit:'cover', height: '100%', zIndex: '-1', position: 'absolute', top: 0}} src={loginPageBackground} alt="loginBackground" />
        <Form
        style={{background: 'white', width: '400px', padding: '20px 20px'}}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Login"
          name="login"
          rules={[
            {
              required: true,
              message: 'Please input your login!',
            },
          ]}
        >
          <Input />
        </Form.Item>
  
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
      </div>
    )



}

export default LoginPage;
import { Button, Table, Drawer, Form, Alert, Input, InputNumber } from 'antd';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Colorpicker } from 'antd-colorpicker'

function SettingsPage() {

  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [saveAlert, setSaveAlert] = useState({ name: null });
  let timer;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
  });

  const getSites = async () => {
    const { data } = await axiosInstance.get('get/sites');
    setSites(data.payload)
  }

  const handleOnFinishColor = async (data) => { 
    setLoading(true);
    if (!data.colorData) {
      data.colorData = { hex: '#000000' };
    }
    const result = await axiosInstance.post('create/color', data);
    if (result.data.status === 'ok') setLoading(false)
    getColors();
  }

  const handleOnFinishSize = async (data) => { 
    setLoading(true);
    const result = await axiosInstance.post('create/size', data);
    if (result.data.status === 'ok') setLoading(false)
    getSizes();
  }

  const getColors = async () => {
    const { data } = await axiosInstance.get('get/colors');
    setColors(data.payload);
  }
  
  const getSizes= async () => {
    const { data } = await axiosInstance.get('get/sizes');
    setSizes(data.payload);
  }

  const removeColor = async (_id) => {
    setLoading(true);
    const result = await axiosInstance.post('remove/color', { _id });
    if (result.data.status === 'ok') setLoading(false)
    getColors();
  }

  const changePriority = async (priority, _id, name) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const data = await axiosInstance.post('change/size/priority', { _id, priority });
      console.log(data)
      setSaveAlert({ name })
      setTimeout(() => {
        setSaveAlert({ name: null })
      }, 2000)
    }, 1000)

  }

  const removeSize = async (_id) => {
    setLoading(true)
    const result = await axiosInstance.post('remove/size', { _id });
    if (result.data.status === 'ok') setLoading(false)
    getSizes();
  }

  useEffect(() => {
    getSites();
    getColors();
    getSizes();
  }, [])
    
    

    return (
      <>
        {/* <Button type='primary' onClick={() => updateNpData()}>Update NP Cities/Offices</Button>
        <Form onFinish={syncProducts}>
          <Form.Item name="site" label="Site">
            <Select 
              placeholder="Site"
              options={(sites || []).map((d) => ({
                  value: d._id,
                  label: d.name, 
              }))} 
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading} icon={loading ? <LoadingOutlined /> : null}>Sync Products</Button>
        </Form>

        <Button type="primary">Update Prices</Button>

        <Button type="primary">Update Products</Button> */}

      {
        colors.map(color => <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
          <p>{color.name}</p>
          <div className='colorCube' style={{backgroundColor: color.hex}}></div>
          <Button disabled={loading} danger onClick={() => removeColor(color._id)}>Remove</Button>
        </div>)

      }

      <Form onFinish={handleOnFinishColor}>
        <Form.Item name="colorName" label='Color' rules={[{ required: true, message: 'Please write color!' }]}>
          <Input placeholder='Color'/>
        </Form.Item>
        <Form.Item name="colorData">
          <Colorpicker/>
        </Form.Item>
        <Form.Item>
          <Button disabled={loading} type='primary' htmlType='submit'>
            Create new color
          </Button>
        </Form.Item>
      </Form>
      {
        sizes.map(size => <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
          <p>{size.name}</p>
          <InputNumber defaultValue={size.priority} placeholder='Priority' style={{width: 100}} onChange={(v) => {changePriority(v, size._id)}} />
          <Button disabled={loading} danger onClick={() => removeSize(size._id)}>Remove</Button>
        </div>)

      }
      { saveAlert.name !== null ? <Alert style={{ position: 'fixed', bottom: 5, left: 5, width: '100%' }} message='Size priority saved' type="success" showIcon /> : <></> }
      <Form onFinish={handleOnFinishSize}>
        <Form.Item name="sizeName" label='Size'>
          <Input placeholder='Size'/>
        </Form.Item>
        <Form.Item>
          <Button disabled={loading} type='primary' htmlType='submit'>
            Create new size
          </Button>
        </Form.Item>
      </Form>
        
      </>
    )
}

export default SettingsPage;
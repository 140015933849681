import { CHANGE_THEME, CHANGE_LANG } from '../reducers/types';

const changeThemeSuccess = (theme) => {
  console.log(theme)
  const action = {
    type: CHANGE_THEME,
    payload: { theme }
  };

  return action;
}

const changeLangSuccess = (value) => {
  const action = {
    type: CHANGE_LANG,
    payload: { value }
  };

  return action;
}

export { changeThemeSuccess, changeLangSuccess };

import { DatePicker, Form, Image, Button, Table } from 'antd';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import placeholder from '../../images/placeholder.jpg';



dayjs.extend(customParseFormat);

const StatisticPage = () => {

    const [sites, setSites] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [ordersTableData, setOrdersTableData] = useState([]);
    const [productsTableData, setProductsTableData] = useState([]);
    const [form] = Form.useForm();

    const navigate = useNavigate();

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BACK_URL,
    });

    let themeSettings = useSelector((state) => state.theme);

    const { t, i18n } = useTranslation();

    const getStatistic = async (payload) => {
        const values =
        { 
            timeRange: { 
                start: payload === undefined ? moment().format('YYYY-MM-DD') : payload.timeRange[0].format('YYYY-MM-DD'),
                end: payload === undefined ? moment().format('YYYY-MM-DD') : payload.timeRange[1].format('YYYY-MM-DD'), 
            }       
        }

        console.log(values)
        
        const result = await axiosInstance.post('get/statistic', values);

        console.log(result.data.payload)

        setTableData(result.data.payload.ordersStats)
        setOrdersTableData(result.data.payload.orders)
        setProductsTableData(result.data.payload.productsStats)
        console.log(result.data.payload.productsStats)
    }

    const getSites = async () => {
        const sites = await axiosInstance.get('get/sites');
        setSites(sites.data.payload);   
    }

    const getStocks = async () => {
        const stocks = await axiosInstance.get('get/stocks');
        setStocks(stocks.data.payload);   
    }


    useEffect(() => {
        getSites();
        getStocks();
        getStatistic()
    }, [])

    const sharedOnCell = (_, index) => {
        if (index === 0 || index === 1 || index === 2) {
          return {
            colSpan: 0,
          };
        }
        return {};
      };
    
    const { RangePicker } = DatePicker;

    const columnsMoney = [
        {
            title: t('type'),
            dataIndex: 'type',
            render: (text) => <>{t(text)}</>,
        },
        {
            title: t('count'),
            dataIndex: 'count',
        },
        {
            title: t('summary'),
            dataIndex: 'summary',
            render: (text) => Array.isArray(text) ? 
                <><div>{t('card')} {text[0]} ₺</div><div>{t('cash')}  {text[1]} ₺</div></> : 
                <div>{text} ₺</div>
        },
        {
            title: t('paid'),
            dataIndex: 'paid',
            render(text, record) {
                return {
                  props: {
                    style: { background: "#4cb051", color: 'white' }
                  },
                  children: <div>{text} ₺</div>
                };
            },
            onCell: sharedOnCell,
        },
        {
            title: t('notPaid'),
            dataIndex: 'notPaid',
            render(text, record) {
                return {
                  props: {
                    style: { background: "#f34436", color: 'white' }
                  },
                  children: <div>{text} ₺</div>
                };
            },
            onCell: sharedOnCell,
        },
        {
            title: t('completed'),
            dataIndex: 'completed',
            render(text, record) {
                return {
                  props: {
                    style: { background: "#4cb051", color: 'white' }
                  },
                  children: <div>{text}</div>
                };
            },
            onCell: sharedOnCell,
        },
        {
            title: t('notCompleted'),
            dataIndex: 'notCompleted',
            render(text, record) {
                return {
                  props: {
                    style: { background: "#f34436", color: 'white' }
                  },
                  children: <div>{text}</div>
                };
            },
            onCell: sharedOnCell,
        },
      ];

    const columnsOrders = [
    {
        title: t('id'),
        dataIndex: 'id',
    },
    {
        title: t('paid'),
        dataIndex: 'paymentStatus',
        render: (text) => <>{t(text)}</>
    },
    {
        title: t('paymentType'),
        dataIndex: 'paymentType',
        filters: [
            {
                text: t('cash'),
                value: 'cash'
            },
            {
                text: t('card'),
                value: 'card'
            }
        ],
        onFilter: (value, record) => record.paymentType.startsWith(value),
        filterSearch: true,
        render: (text) => <>{t(text)}</>
    },
    {
        title: t('completed'),
        dataIndex: 'orderStatus',
        render: (text) => <>{t(text)}</>
    },
    {
        title: t('client'),
        dataIndex: ['client', 'name'],
        render: (text) => <>{t(text)}</>
    },
    {
        title: t('deliveryType'),
        dataIndex: 'deliveryType',
        render: (text) => <>{t(text)}</>
    },
    {
        title: t('deliveryStatus'),
        dataIndex: 'deliveryStatus',
        // filters: [
        //     {
        //         text: t('Completed'),
        //         value: 'Completed'

        //     }
        // ],
        // onFilter: (value, record) => record.deliveryStatus.startsWith(value),
        // filterSearch: true,
        render: (text) => <>{t(text)}</>
    },
    {
        title: t('summary'),
        dataIndex: 'summary',
        key: 'summary',
        width: 95,
        render: (text, { npDelivery }) => <p>{ text } ₺<br />{ npDelivery ? npDelivery['deliveryCost'] : 0 } ₺ 🚚</p>
      },
      {
        title: t('created'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 100,
        render: (text) => <>{moment(text).format("DD.MM.YYYY HH:mm:ss")}</>
      },
      {
        key: 'action',
        width: 20,
        render: (text, { id }) => <Button onClick={() => {navigate(`../view/order/${id}`)}} icon={<EyeOutlined />} />		
      }
    ];

    const columnsProducts = [
        { 
            title: t('image'), 
            key: ['_id', 'images'],
            render: (text, { _id }) => <div className="productImages">
              <Image.PreviewGroup>
                {
                  _id.images && _id.images.length > 0 ?
                  _id.images.map((image, key) => 
                    <Image key={key} className="productImage" width={70} src={process.env.REACT_APP_BACK_URL + 'uploads/' + image.url} />
                  )
                  :
                  <Image className="productImage" width={70} src={placeholder} />
                }
              </Image.PreviewGroup>
            </div>
          },  
        {
            title: t('name'),
            dataIndex: ['_id', 'names', themeSettings.lang],
        },
        {
            title: t('category'),
            dataIndex: ['_id', 'category', 'name'],
        },
        {
            title: t('color'),
            dataIndex: ['_id', 'color'],
            key: 'color',
            render: (text, { _id }) => 
            text !== undefined ?
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>{text.name} <div className='colorCube' style={{backgroundColor: text.hex}}></div></div>
            : 
            <></>
        },
        {
            title: t('size'),
            dataIndex: ['_id', 'size', 'name'],
            key: 'size',
        },
        {
            title: t('quantity'),
            dataIndex: 'quantity',
        },
        {
            title: t('price'),
            dataIndex: 'price',
            render: text => <>{text} ₺</>
        },
        {
            title: t('wholeSalePrice'),
            dataIndex: ['_id', 'wholeSalePrice'],
            render: text => <>{text} ₺</>
        },
        {
            title: t('summary'),
            render: (text, { quantity, price }) => <>{quantity * price} ₺</>
        },
        {
            title: t('profit'),
            render: (text, { profit }) => <>{profit} ₺</>
        },
        {
            title: t('created'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            render: (text) => <>{moment(text).format("DD.MM.YYYY HH:mm:ss")}</>
        }
    ];

    return (
        <>

            <div>
                <Form 
                    layout='inline'
                    style={{display: 'flex', width: '100%', padding: '10px 20px'}}
                    onFinish={getStatistic}
                >
                    <Form.Item name="timeRange">
                        <RangePicker
                            defaultValue={[dayjs(moment().format('YYYY-MM-DD'), "YYYY-MM-DD"), dayjs(moment().format('YYYY-MM-DD'), "YYYY-MM-DD")]}
                            format="YYYY-MM-DD"
                        />    
                    </Form.Item>
                    {/* <Form.Item name="stock">
                        <Select
                            showSearch
                            placeholder="Select a stock"
                            options={(stocks || []).map((d) => ({
                                value: d._id,
                                label: d.name,
                            }))}
                        />
                    </Form.Item>
                    <Form.Item name="deliveryType">
                        <Select
                            showSearch
                            placeholder="Select a delivery type"
                            options={[
                                {
                                    value: 'selfpickup',
                                    label: 'Selfpickup'
                                },
                                {
                                    value: 'courierDelivery',
                                    label: 'Courier Delivery'
                                },
                                {
                                    value: 'novaposhtaDelivery',
                                    label: 'Novaposhta Delivery'
                                },
                            ]}
                        />
                    </Form.Item> */}
                    <Form.Item>
                        <Button htmlType="submit" type="primary" icon={<SearchOutlined />} />
                    </Form.Item> 
                </Form>

            </div>

            <Table
                size="small"
                pagination={false}
                bordered
                columns={columnsMoney}
                dataSource={tableData}
                scroll={{
                    x: 1300,
                }}
            />

            <Table
                size="small"
                bordered
                columns={columnsOrders}
                dataSource={ordersTableData}
                scroll={{
                    x: 1300,
                }}
            />

            <Table
                size="small"
                bordered
                columns={columnsProducts}
                dataSource={productsTableData}
                scroll={{
                    x: 1300,
                }}
            />
        </> 
    )

}

export default StatisticPage;
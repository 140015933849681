import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Button, Table, Col, Row, Image, Divider } from 'antd';
import { useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import placeholder from '../../images/placeholder.jpg';

import OrderInfoFrom from '../../components/ViewOrderForm/OrderInfoForm';

const formItemLayout = {
  labelCol: { span: 24 },
}

const App = () => {

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
  });

  const { t, i18n } = useTranslation();

  let themeSettings = useSelector((state) => state.theme);

  let { stocks } = useSelector((state) => state.auth);

  let { id } = useParams();

  const [choosedProducts, setProduct] = useState([]);
  const [deliveryType, setDeliveryType] = useState([]);
  const [summary, setSummary] = useState([]);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    // await axiosInstance.post('create/order', values);
  };

  const productsSummary = () => {
      let sum = 0;
      choosedProducts.forEach(item => {
        sum += item.quantity * item.price
      })
      form.setFieldsValue({
        summary: sum,
        assessedValue: sum,
    });
    setSummary(sum);
  }

  const getOrder = async () => {
    const { data } = await axiosInstance.post('get/order', { id });
    const choosed = data.payload.products.map((item, key) => ({ 
        _id: item._id,
        names: item._id.names,
        price: item.price,
        quantity: item.quantity,
        sku: item._id.sku,
        model: item._id.model,
        category: item._id.category,
        images: item._id.images,
        size: item._id.size,
        color: item._id.color,
        updatedAt: item._id.updatedAt,
        createdAt: item._id.createdAt,
          key,
    }))
    setDeliveryType(data.payload.deliveryType)
    setProduct(choosed);
    console.log(data.payload)
    form.setFieldsValue({
      site: data.payload.site._id,
      stock: data.payload.stock._id,
      deliveryType: data.payload.deliveryType,
      paymentType: data.payload.paymentType,
      orderStatus: data.payload.orderStatus,
      paymentStatus: data.payload.paymentStatus,
      fullname: data.payload.client.name,
      telephone: data.payload.client.phone,
      email: data.payload.client.email,
      comment: data.payload.comment,
    });
  }

  const columnsChoosed = [
    { 
      title: t('image'), 
      key: 'images',
      render: (text, { images }) => <div className="productImages">
        <Image.PreviewGroup>
          {
            images && images.length > 0 ?
            images.map((image, key) => 
              <Image key={key} className="productImage" width={70} src={process.env.REACT_APP_BACK_URL + 'uploads/' + image.url} />
            )
            :
            <Image className="productImage" width={70} src={placeholder} />
          }
        </Image.PreviewGroup>
      </div>
    },  
    { 
      title: t('name'),
      dataIndex: ['names', themeSettings.lang],
      key: 'names', 
    },  
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.quantity - b.quantity,
      render: (text, {_id}) => <InputNumber value={text} />
    },
    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (text, {_id}) => <InputNumber value={text} />
    },
    {
      title: t('sku'),
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: t('color'),
      dataIndex: ['color', 'name'],
      key: 'color',
      render: (text, { color }) => color ?
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>{text} <div className='colorCube' style={{backgroundColor: color.hex}}></div></div>
      :
      <></>
    },
    {
      title: t('size'),
      dataIndex: ['size', 'name'],
      key: 'size',
    },
    {
      title: t('category'),
      dataIndex: ['category', 'name'],
      key: 'category',
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
        products: choosedProducts,
    });
    productsSummary();
  }, [form, choosedProducts]);

  useEffect(() => {
    getOrder();
  }, []);


  return (
    <>
    <Form
      form={form}
      disabled
      layout='inline'
      onFinish={onFinish}
      initialValues={{products: choosedProducts}}
      {...formItemLayout}
    >  
      <Row style={{width: '100%'}}>
        <Col span={12} style={{padding: '0 10px'}}>
          <Divider orientation="left" orientationMargin="0">{t('orderProducts')}</Divider>
          <Table 
              columns={columnsChoosed} dataSource={(choosedProducts || [])}         
              style={{
                height: 240,
                overflow: 'auto'
              }}   />
        </Col>
      <Col span={12} style={{padding: '0 10px'}}>
          <OrderInfoFrom form={form} summary={summary} deliveryType={deliveryType} />
      </Col>
    </Row>
   </Form>
    </>
  );
};
export default App;
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Drawer, Form, Alert, Input, Space, Select, InputNumber } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function SitesPage() {
  
    const [open, setOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Error');
    const [expenses, setExpenses] = useState([]);
    const [cashregisters, setCashregisters] = useState([]);

    const onClose = () => {
        setOpen(false);
    };

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACK_URL,
    });

    const { t, i18n } = useTranslation();

    const submitExpenseCreate = async (values) => {
        const { data } = await axiosInstance.post('create/expense', values);
        if (data.status === 'ok') {
          setOpen(false);
          getExpenses();
        } 
    }
    
    const getExpenses = async () => {
      const expenses = await axiosInstance.post('get/expenses');
      setExpenses(expenses.data.payload);
    }

    const getCashregisters = async () => {
      const cashregisters = await axiosInstance.get('get/cashregisters');
      console.log(cashregisters)
      setCashregisters(cashregisters.data.payload);
    }

    const removeExpense = async (_id) => {
      await axiosInstance.post('remove/expense', { _id });
      getExpenses();
    }

    const [form] = Form.useForm();

    const showDrawer = () => {
      form.setFieldsValue({
        name: '',
        domain: '',
      });
      setOpen(true);
    };

    useEffect(() => {
      getExpenses();
      getCashregisters();
    }, [])
    
    const columns = [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('cashregister'),
        dataIndex: [ 'cashregister' , 'name' ],
        key: 'cashregisterName',
      },
      {
        title: t('paymentType'),
        dataIndex: 'paymentType',
        key: 'paymentType',
      },
      {
        title: t('summary'),
        dataIndex: 'summary',
        key: 'summary',
      },
      {
        title: t('comment'),
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: t('updated'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text) => <>{moment(text).format("DD.MM.YYYY HH:mm:ss")}</>
      },
      {
        title: t('created'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => <>{moment(text).format("DD.MM.YYYY HH:mm:ss")}</>
      },
      {
        render: (text, { _id }) => 
          <>
            <Button danger type="primary" onClick={() => {removeExpense(_id)}} icon={<DeleteOutlined />} />
          </>
        
      },
    ];


    return (
        <>
      <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
        New Expense
      </Button>

      <Table dataSource={expenses} columns={columns} />


      <Drawer
        title={ t('create') }
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>{}</Button>
          </Space>
        }
      >
        <Form layout="vertical" onFinish={ submitExpenseCreate } form={form}>
              <Form.Item
                name="name"
                label={ t('name') }
                rules={[
                  {
                    required: true,
                    message: 'Please enter user name',
                  },
                ]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
              <Form.Item
                name="paymentType"
                label={ t('paymentType') }
                rules={[
                  {
                    required: true,
                    message: 'Please select payment type',
                  },
                ]}
              >
                <Select
                  placeholder="Select a paymentType"
                  options={[
                    {
                      label: t('card'),
                      value: 'card'
                    },
                    {
                      label: t('cash'),
                      value: 'cash'
                    }
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="summary"
                label={t('summary')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter summary',
                  },
                ]}
              >
                <InputNumber style={{width: '100%'}} placeholder="Please enter summary" />
              </Form.Item>
              <Form.Item
                name="cashregister"
                label={t('cashregisters')}
                rules={[
                  {
                    required: true,
                    message: 'Please select cashregister',
                  },
                ]}
              >
              <Select
                showSearch
                placeholder="Select a cashregister"
                options={(cashregisters || []).map((d) => ({
                    value: d._id,
                    label: d.name,
                  }))}
              />
              </Form.Item>   
              <Form.Item
                name="comment"
                label={t('comment')}
              >
                <TextArea style={{width: '100%'}} placeholder="Please enter comment" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">{t('create')}</Button>
              </Form.Item>
              <Alert
                message="Error"
                description={alertMessage}
                type="error"
                showIcon
                style={{display: alertStatus ? 'block' : 'none'}}
              />
        </Form>
      </Drawer>
    </>
    )
}

export default SitesPage
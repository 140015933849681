import { Form, Input, Button, Select, Divider, InputNumber, DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function OrderInfoForm (props) {

    const [cities, setCities] = useState([]);
    const [offices, setOffices] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [officeOptions, setOfficeOptions] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [sites, setSites] = useState([]);
    const [stocks, setStocks] = useState([]); 
    const [npAccounts, setNpAccounts] = useState([]);
    const [deliveryType, setDeliveryType] = useState('selfpickup');
    const [postomat, setPostomat] = useState(false);
    const [hideExtra, setHideExtra] = useState(false);
    const [clientPaid, setClientPaid] = useState(0);
    const [discount, setDiscount] = useState(0);

    const { t, i18n } = useTranslation();

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BACK_URL,
    });

    let reduxStorage = useSelector((state) => state.auth);

    console.log(reduxStorage.stocks[0]._id)

    const getNovaposhtaData = async () => {
        const { data } = await axiosInstance.get('get/novaposhta');
        setCities(data.payload.cities);
        setOffices(data.payload.offices);
    };

    const getSites = async () => {
        const { data } = await axiosInstance.get('get/sites');
        setSites(data.payload)
    }

    const getStocks = async () => {
        const { data } = await axiosInstance.get('get/stocks');
        setStocks(data.payload)
    }

    const getNpAccounts = async () => {
        const { data } = await axiosInstance.get('get/novaposhta/accounts');
        setNpAccounts(data.payload);
      }

    const onChangePostOffice = (data) => {
        const result = offices.filter(item => item.Ref === data.value);
        if (result[0].CategoryOfWarehouse === 'Postomat') {
            setPostomat(true);
        } else {
            setPostomat(false);
        }
    };

    const onSearchCity = (value) => {
        const results = cities.filter(item => item.Description.toLowerCase().search(value.toLowerCase()) !== -1);
        setCityOptions(results)
    };

    const onSearchOffice = (value) => {
        const results = offices.filter(item => item.Description.toLowerCase().search(value.toLowerCase()) !== -1);
        const filtred = results.filter(item => item.SettlementRef === selectedCity);

        setOfficeOptions(filtred)
    };

    useEffect(() => {
        getNovaposhtaData();
        getSites();
        getStocks();
        getNpAccounts();
    }, []);

    const onChangeDiscount = (value) => {
        setDiscount(value);
    }

    useEffect(() => {
        const discountSum = discount > 0 ? (props.summary / 100 * (100 - discount)).toFixed(2) : props.summary.toFixed(2);
        const change = clientPaid - discountSum;

        console.log('e12312')

        props.form.setFieldsValue({
            change: change.toFixed(2),
            summary: discountSum,
        })    
    }, [props.summary, discount, clientPaid])
    
    return (
        <>
            <Divider orientation="left" orientationMargin="0">{t('orderInfo')}</Divider>
            <div className='oneFourForm'>
                <Form.Item name="site" label={t('site')} rules={[{ required: true, message: 'Please select site!' }]}>
                    <Select
                        placeholder={t('site')}
                        defaultValue={reduxStorage.sites[0]._id}
                        options={(sites || []).map((d) => ({
                            value: d._id,
                            label: d.name,
                        }))} 
                    />
                </Form.Item>
                <Form.Item name="stock" label={t('stock')} rules={[{ required: true, message: 'Please select stock!' }]}>
                    <Select 
                        placeholder={t('stock')}
                        defaultValue={reduxStorage.stocks[0]._id}
                        options={(stocks || []).map((d) => ({
                            value: d._id,
                            label: d.name,
                        }))} 
                    />
                </Form.Item>
                <Form.Item name="deliveryType" label={t('deliveryType')} rules={[{ required: true, message: 'Please select delivery type!' }]}>
                    <Select placeholder="Delivery Type" onChange={(value) => {setDeliveryType(value)}} defaultValue="selfpickup" >
                        <Option value="selfpickup">{t('selfpickup')}</Option>
                        <Option value="courierDelivery">{t('courierDelivery')}</Option>
                        <Option value="novaposhtaDelivery">{t('novaposhtaDelivery')}</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="paymentType" label={t('paymentType')} rules={[{ required: true, message: 'Please select payment type!' }]}>
                    <Select placeholder="Payment Type">
                        <Option value="cash">{t('cash')}</Option>
                        <Option value="card">{t('card')}</Option>
                    </Select>
                </Form.Item>
            </div>
            <div className="oneTwoForm">
                <Form.Item name="orderStatus" label={t('orderStatus')} rules={[{ required: true, message: 'Please select order status!' }]}>
                    <Select placeholder={t('orderStatus')}>
                        <Option value="confirmed">{t('confirmed')}</Option>
                        <Option value="notConfirmed">{t('notConfirmed')}</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="paymentStatus" label={t('paymentStatus')} rules={[{ required: true, message: 'Please select payment status!' }]}>
                    <Select placeholder={t('paymentStatus')}>
                        <Option value="paid">{t('paid')}</Option>
                        <Option value="notPaid">{t('notPaid')}</Option>
                    </Select>
                </Form.Item>
            </div>
            <Divider orientation="left" orientationMargin="0" style={{display: deliveryType !== 'selfpickup' ? 'flex' : 'none'}}>{t('orderDelivery')}</Divider>
            <div style={{display: deliveryType === 'courierDelivery' ? 'block' : 'none'}}>
                <Form.Item name="address" label={t('address')} rules={[{ required: deliveryType === 'courierDelivery', message: 'Please input address!' }]}>
                    <Input placeholder="Address" />
                </Form.Item>
            </div>
            <div style={{display: deliveryType === 'novaposhtaDelivery' ? 'block' : 'none'}}>
                <div className='oneFourForm'>
                    <Form.Item name="npAccount" label={t('npAccount')} rules={[{ required: deliveryType === 'novaposhtaDelivery', message: 'Please select novaposhta account!' }]}>
                    <Select 
                            placeholder={t('npAccount')}
                            options={(npAccounts || []).map((d) => ({
                                value: d._id,
                                label: d.name,
                            }))} 
                        />
                    </Form.Item>
                    <Form.Item name="weight" label={t('weight')} style={{ display: !hideExtra ? 'block' : 'none' }} rules={[{ required: deliveryType === 'novaposhtaDelivery' && !hideExtra, message: 'Please input weight!' }]}>
                        <Input placeholder={t('weight')} />
                    </Form.Item>
                    <Form.Item name="assessedValue" label={t('assessedValue')} rules={[{ required: deliveryType === 'novaposhtaDelivery', message: 'Please input assessed value!' }]}>
                        <Input placeholder={t('assessedValue')} />
                    </Form.Item>
                    <Form.Item name="ttn" label={t('ttn')} rules={[{ required: deliveryType === 'novaposhtaDelivery' && hideExtra, message: 'Please input ttn!' }]}>
                        <Input placeholder={t('ttn')} onChange={e => {
                            if (e.target.value.length > 0) {
                                setHideExtra(true);
                            } else {
                                setHideExtra(false);
                            }
                        }} />
                    </Form.Item>
                </div>
                <div className='oneFourForm' style={{ display: !hideExtra ? 'grid' : 'none' }}>
                    <Form.Item name="deliveryPayer" label={t('deliverPayer')} rules={[{ required: deliveryType === 'novaposhtaDelivery' && !hideExtra, message: 'Please select delivery payer!' }]}>
                        <Select placeholder={t('deliveryPayer')}>
                            <Option value="Recipient">{ t('recipient')}</Option>
                            <Option value="Sender">{t('sender')}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="parcelWidth" label={t('parcelWidth')} style={{ display: postomat ? 'block' : 'none' }} rules={[{ required: postomat, message: 'Please input width!' }]}>
                        <Input placeholder={t('parcelWidth')} />
                    </Form.Item>
                    <Form.Item name="parcelHeight" label={t('parcelHeight')} style={{ display: postomat ? 'block' : 'none' }} rules={[{ required: postomat, message: 'Please input height!' }]}>
                        <Input placeholder={t('parcelHeight')} />
                    </Form.Item>
                    <Form.Item name="parcelLength" label={t('parcelLength')} style={{ display: postomat ? 'block' : 'none' }} rules={[{ required: postomat, message: 'Please input length!' }]}>
                        <Input placeholder={t('parcelLength')} />
                    </Form.Item>
                </div>
                <div className="oneTwoForm" style={{ display: !hideExtra ? 'grid' : 'none' }}>
                    <Form.Item name="city" label={t('city')} rules={[{ required: deliveryType === 'novaposhtaDelivery' && !hideExtra, message: 'Please select city!' }]}>
                    <Select
                        labelInValue
                        showSearch
                        placeholder="Select a city"
                        optionFilterProp="children"
                        onChange={data => {setSelectedCity(data.value)}}
                        onSearch={onSearchCity}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={(cityOptions || []).map((d) => ({
                            value: d.Ref,
                            label: d.Description,
                        }))}
                    />
                    </Form.Item>
                    <Form.Item name="postOffice" label={t('postOffice')} rules={[{ required: deliveryType === 'novaposhtaDelivery' && !hideExtra, message: 'Please select post office!' }]}>
                    <Select
                        labelInValue
                        showSearch
                        placeholder="Select a post office"
                        optionFilterProp="children"
                        onSearch={onSearchOffice}
                        onChange={onChangePostOffice}
                        filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={(officeOptions || []).map((d) => ({
                            value: d.Ref,
                            label: d.Description,
                        }))}
                    />
                    </Form.Item>
                </div>
            </div>
            <div className="oneTwoForm">
                <Form.Item name="createdAt" label={t('createdAt')}>
                    <DatePicker
                        defaultValue={dayjs(moment().format())} 
                        showTime
                    />
                </Form.Item>
            </div>
            <Divider orientation="left" orientationMargin="0">{t('clientInfo')}</Divider>
            <div className='oneFourForm'>
                <Form.Item name="fullname" label={t('fullname')} style={{ display: !hideExtra ? 'grid' : 'none' }}>
                    <Input placeholder="Full name" />
                </Form.Item>
                <Form.Item name="telephone" label={t('phone')} style={{ display: !hideExtra ? 'grid' : 'none' }}>
                    <InputMask mask="+\90-999-9999999" maskChar="_">
                        { () => <Input /> }
                    </InputMask>
                </Form.Item>
                <Form.Item name="email" label={t('email')}>
                    <Input placeholder="example@gmail.com" />
                </Form.Item>
                <Form.Item name="discount" label={t('discount')}>
                    <Select onChange={(v) => {onChangeDiscount(v)}}>
                        <Option value="0">0%</Option>
                        <Option value="2">2%</Option>
                        <Option value="5">5%</Option>
                        <Option value="10">10%</Option>
                        <Option value="15">15%</Option>
                        <Option value="20">20%</Option>
                    </Select>
                </Form.Item>
            </div>
            <Form.Item name="comment" label={t('comment')} style={{width: '100%'}}>
                <Input.TextArea allowClear showCount />
            </Form.Item>
            <div className='oneThreeForm'>
                <Form.Item name="summary" label={t('summary')}>
                    <InputNumber placeholder={t('summary')} disabled addonAfter='₴' />
                </Form.Item>
                <Form.Item name="clientPaid" label={t('clientPaid')}>
                    <InputNumber placeholder={t('clientPaid')} onChange={(value) => {setClientPaid(value)}} addonAfter='₴' />
                </Form.Item>
                <Form.Item name="change" label={t('change')}>
                    <InputNumber placeholder={t('change')} disabled addonAfter='₴' />
                </Form.Item>
            </div>
            <Form.Item style={{margin: '15px 0'}}>
                <Button type="primary" htmlType="submit">
                    {t('create')}
                </Button>
            </Form.Item>
        </>
    );
}

export default OrderInfoForm;
/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import update from 'react-addons-update';
import { 
  AUTH_UPDATE_TOKENS, 
  AUTH_FAILED, 
  AUTH_IN_PROGRESS, 
  AUTH_SUCCESS, 
  AUTH_LOGOUT,
  USER_EDIT_SUCCESS
} from './types';

// The initial state of the App
export const initialState = {
  id: null,
  name: null,
  login: null,
  status: 'guest', // guest, in_progress, auth
  tokens: { access: null, refresh: null },
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case AUTH_UPDATE_TOKENS:
        // payload = { profile: ... , tokens: { access: ... , refresh ...} }
        return update(state, {
          profile: { $set: action.payload.profile },
          tokens: { 
            access: { $set: action.payload.tokens.access },
            refresh: { $set: action.payload.tokens.refresh }
          }
        });
        break;

      case AUTH_FAILED:

        break;

      case AUTH_IN_PROGRESS:
        return update(state, {
          status:  { $set: 'in_progress' },
      });       
        break;

      case AUTH_LOGOUT:
          return update(state, {
            id: { $set: null },
            status:  { $set: 'guest' },
            tokens: { 
              access: { $set: null },
              refresh: { $set: null }
            }
          });
          break;

      case AUTH_SUCCESS:
        console.log(action)
        return update(state, {
          id: { $set: action.payload.name },
          name: { $set: action.payload.name },
          login: { $set: action.payload.login },
          sites: { $set: action.payload.sites },
          stocks: { $set: action.payload.stocks },
          cashregisters: { $set: action.payload.cashregisters },
          status:  { $set: 'auth' },
        });

        break;

      case USER_EDIT_SUCCESS:
        return update(state, {
          id: { $set: action.payload.name },
          name: { $set: action.payload.name },
          login: { $set: action.payload.login },
          sites: { $set: action.payload.sites },
          stocks: { $set: action.payload.stocks },
          cashregisters: { $set: action.payload.cashregisters },
          status:  { $set: 'auth' },
        });

        break;
    }

    return state;
  };

export default reducer;

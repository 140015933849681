import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, Drawer, Form, Alert, Select, Input, Space } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';

function CashregistersPage() {
  
    const [open, setOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Error');
    const [sitesData, setSitesData] = useState([]);
    const [stocksData, setStocksData] = useState([]);
    const [cashregistersData, setCashregistersData] = useState([]);
    const [editingCashregister, setEditingCashregister] = useState([]);

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACK_URL,
    });

    const onClose = () => {
        setOpen(false);
    };

    const submitCashregisterkCreate = async (values) => {
        const { data } = await axiosInstance.post('create/cashregister', values);
        console.log(data)
        if (data.status === 'ok') {
          setOpen(false);
          getCashregisters();
        } else if (data.status === 'failed name') {
          setAlertMessage('This site name is already taken');
          setAlertStatus(true);
          setTimeout(() => {setAlertStatus(false)}, 5000);
        } else if (data.status === 'failed domain') {
          setAlertMessage('This site domain is already taken');
          setAlertStatus(true);
          setTimeout(() => {setAlertStatus(false)}, 5000);
        }
          
    }

    const submitCashregisterEdit = async (values) => {
      const result = await axiosInstance.post('edit/stock', { old: editingCashregister, new: values });
      setOpen(false);
      getStocks();
    }
    
    const getSites = async () => {
      const sites = await axiosInstance.get('get/sites');
      setSitesData(sites.data.payload);
    }

    const getStocks = async () => {
        const stocks = await axiosInstance.get('get/stocks');
        setStocksData(stocks.data.payload);
    }

    const getCashregisters = async () => {
      const cashregisters = await axiosInstance.get('get/cashregisters');
      setCashregistersData(cashregisters.data.payload);
  }

    const removeCashregister = async (name) => {
      await axiosInstance.post('remove/cashregister', { name });
      getCashregisters();
    }

    const [form] = Form.useForm();

    const showDrawer = () => {
      setEditingCashregister([])
      form.setFieldsValue({
        name: '',
        site: '',
      });
      setOpen(true);
    };

    const editCashregister = async (name) => {
      setEditingCashregister((cashregistersData.find((item) => item.name === name) || []));
      setOpen(true);
    }

    useEffect(() => {
      getSites();
      getStocks();
      getCashregisters();
    }, [])
    
    useEffect(() => {
      const { site, stock } = editingCashregister;
        form.setFieldsValue({
            name: editingCashregister.name,
            site: site && site._id,
            stock: stock && stock._id,
        });
    }, [form, editingCashregister]);

    
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Site',
        dataIndex: ['site', 'name'],
        key: 'site',
      },
      {
        title: 'Card',
        dataIndex: 'card',
        key: 'card',
        render: (text) => <>{ text } ₺</>
      },
      {
        title: 'Cash',
        dataIndex: 'cash',
        key: 'cash',
        render: (text) => <>{ text } ₺</>
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        width: 30,
        render: (text, { name }) => 
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Button type="primary" onClick={() => editCashregister(name)} icon={<EditOutlined />} />
            <Button danger type="primary" onClick={() => removeCashregister(name)} icon={<DeleteOutlined />} />
          </div>
        
      },
    ];


    return (
        <>
      <Button style={{float: 'right', margin: '5px'}} type="primary" onClick={showDrawer} icon={<PlusOutlined />} />

      <Table dataSource={cashregistersData} columns={columns} scroll={{x: 1300}} />


      <Drawer
        title={ editingCashregister.length !== 0 ? "Edit a cashregister" : "Create new cashregister" }
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Form layout="vertical" onFinish={ editingCashregister.length !== 0 ? submitCashregisterEdit   : submitCashregisterkCreate } form={form}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter site name',
                  },
                ]}
              >
                <Input placeholder="Please enter site name" />
              </Form.Item>
              <Form.Item
                name="site"
                label="Site"
                rules={[
                  {
                    required: true,
                    message: 'Please select site',
                  },
                ]}
              >
                <Select
                  placeholder="Select a site"
                  options={(sitesData || []).map((d) => ({
                      value: d._id,
                      label: d.name,
                    }))}
                />
              </Form.Item>   
              <Form.Item
                name="stock"
                label="Stock"
                rules={[
                  {
                    required: true,
                    message: 'Please select stock',
                  },
                ]}
              >
                <Select
                  placeholder="Select a stock"
                  options={(stocksData || []).map((d) => ({
                      value: d._id,
                      label: d.name,
                    }))}
                />
              </Form.Item>   
              <Form.Item>
                <Button htmlType="submit" type="primary">{ editingCashregister.length !== 0 ? "Edit" : "Create"}</Button>
              </Form.Item>
              <Alert
                message="Error"
                description={alertMessage}
                type="error"
                showIcon
                style={{display: alertStatus ? 'block' : 'none'}}
              />
        </Form>
      </Drawer>
    </>
    )
}

export default CashregistersPage;
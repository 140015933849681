import { Form, Select, Transfer, Button } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';

function AcceptProductsPage() {

  const [products, setProducts] = useState([]);
  const [stockProducts, setStockProducts] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
  });


  useEffect(() => {
    getStocks();
    getSites();

  }, []);

  const onChange = async (newTargetKeys, direction, moveKeys) => {

    const stockProductsMoved = moveKeys.map(item => (products[item]._id));

    await axiosInstance.post('move/products', { stockProductsMoved, selectedValue })

    getProducts(selectedValue);
  };


  const getProducts = async ({ stock, site }) => {
    const { data } = await axiosInstance.post('get/accept/products', { stock, site });
    const products = data.payload.products.map((item, i) => ({ ...item, key: i }));
    const stockProducts = data.payload.stockProducts;
    const targetProducts = [];

    stockProducts.forEach((product) => {
      const data = products.filter(item => item._id === product._id);
      if (!data[0]) return 
      targetProducts.push(data[0].key);
    })

    setSelectedValue({ stock, site });
    setProducts(products); 
    setStockProducts(targetProducts);
  }

  const getStocks = async () => {
    const { data }  = await axiosInstance.get('get/stocks');
    setStocks(data.payload);
  }

  const getSites = async () => {
    const { data }  = await axiosInstance.get('get/sites');
    setSites(data.payload);
  }

  return (
    <>
      <Form layout='inline' onFinish={getProducts}>
        <Form.Item name="stock">
          <Select 
            placeholder="Stocks"
            options={(stocks || []).map((d) => ({
                value: d._id,
                label: d.name,
            }))} 
          />
        </Form.Item>
        <Form.Item name="site">
          <Select 
            placeholder="Sites"
            options={(sites || []).map((d) => ({
                value: d._id,
                label: d.name,
            }))} 
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Find</Button>
        </Form.Item>
      </Form>
      <Transfer
        dataSource={products}
        targetKeys={stockProducts}
        onChange={onChange}
        listStyle={{
          width: 300,
          height: 300,
        }}
        render={(item) => item.name}
        pagination
        oneWay
      />
    </>
  );
}

export default AcceptProductsPage;

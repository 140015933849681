import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import './i18n';
import {
  BrowserRouter
} from "react-router-dom";

import { configureStore } from './configureStore';
import { PersistGate } from 'redux-persist/integration/react';

const initialState = {};
const store = configureStore(initialState);
const persistor = persistStore(store);
 
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <App />
        </Provider>
      </PersistGate>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Table } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function SitesPage() {

    const { t, i18n } = useTranslation();
  
    const [clients, setClients] = useState([]);

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACK_URL,
    });
    
    const getClients = async () => {
      const clients = await axiosInstance.post('get/clients');
      setClients(clients.data.payload);
      
    }

    useEffect(() => {
      getClients();
    }, [])
    
    const columns = [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('phone'),
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: t('email'),
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: t('created'),
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
    ];


    return (
      <>
        <Table dataSource={clients} columns={columns} />
      </>
    )
}

export default SitesPage
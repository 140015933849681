import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Table, Drawer, Form, Alert, Input, Space } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function SitesPage() {
  
    const [open, setOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Error');
    const [sitesData, setSitesData] = useState([]);
    const [editingSite, setEditingSite] = useState([]);
    const { t, i18n } = useTranslation();


    const onClose = () => {
        setOpen(false);
    };

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACK_URL,
    });

    const submitSiteCreate = async (values) => {
        const { data } = await axiosInstance.post('create/site', values);
        if (data.status === 'ok') {
          setOpen(false);
          getSites();
        } else if (data.status === 'failed name') {
          setAlertMessage('This site name is already taken');
          setAlertStatus(true);
          setTimeout(() => {setAlertStatus(false)}, 5000);
        } else if (data.status === 'failed domain') {
          setAlertMessage('This site domain is already taken');
          setAlertStatus(true);
          setTimeout(() => {setAlertStatus(false)}, 5000);
        }
          
    }

    const submitSiteEdit = async (values) => {
      await axiosInstance.post('edit/site', { old: editingSite, new: values });
      setOpen(false);
      getSites();
    }
    
    const getSites = async () => {
      const sites = await axiosInstance.get('get/sites');
      setSitesData(sites.data.payload);
      
    }

    const removeSite = async (name) => {
      await axiosInstance.post('remove/site', { name });
      getSites();
    }

    const [form] = Form.useForm();

    const showDrawer = () => {
      setEditingSite([])
      form.setFieldsValue({
        name: '',
        domain: '',
      });
      setOpen(true);
    };

    const editSite = async (name) => {
      setEditingSite(sitesData.find((item) => item.name === name));
      setOpen(true);
    }

    useEffect(() => {
      getSites();
    }, [])
    
    useEffect(() => {
      form.setFieldsValue({
          name: editingSite.name,
          domain: editingSite.domain,
      });
    }, [form, editingSite]);

    
    const columns = [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Domain',
        dataIndex: 'domain',
        key: 'domain',
      },
      {
        title: t('updated'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
      },
      {
        title: t('created'),
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        width: 30,
        render: (text, { name }) => 
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Button onClick={() => removeSite(name)} danger type="primary" icon={<DeleteOutlined />} />
            <Button onClick={() => editSite(name)} icon={<EditOutlined />} />
          </div>
        
      },
    ];


    return (
        <>
        <Button style={{ float: 'right', margin: '5px' }} type="primary" onClick={showDrawer} icon={<PlusOutlined />} />

      <Table 
        dataSource={sitesData} 
        columns={columns}
        scroll={{
          x: 500,
        }}
      />


      <Drawer
        title={ editingSite.length !== 0 ? t('edit') : t('create') }
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>{t('cancel')}</Button>
          </Space>
        }
      >
        <Form layout="vertical" onFinish={ editingSite.length !== 0 ? submitSiteEdit : submitSiteCreate } form={form}>
              <Form.Item
                name="name"
                label={t('name')}
                rules={[
                  {
                    required: true,
                    message: 'Please enter user name',
                  },
                ]}
              >
                <Input placeholder="Please enter user name" />
              </Form.Item>
              <Form.Item
                name="domain"
                label="Url"
                rules={[
                  {
                    required: true,
                    message: 'Please enter url',
                  },
                ]}
              >
                <Input
                  style={{
                    width: '100%',
                  }}
                  addonBefore="http://"
                  placeholder="Please enter url"
                />
              </Form.Item>   
              <Form.Item>
                <Button htmlType="submit" type="primary">{ editingSite.length !== 0 ? t('edit') : t('create')}</Button>
              </Form.Item>
              <Alert
                message="Error"
                description={alertMessage}
                type="error"
                showIcon
                style={{display: alertStatus ? 'block' : 'none'}}
              />
        </Form>
      </Drawer>
    </>
    )
}

export default SitesPage
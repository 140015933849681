import { Outlet } from "react-router-dom";
import { ShoppingCartOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
import axios from 'axios';
import { Column } from '@ant-design/plots';
import { useRef, useState, useEffect } from 'react';


const DemoColumn = () => {
  const data = [
    {
      month: 'Jan',
      money: 86340,
    },
    {
      month: 'Feb',
      money: 77053,
    },
    {
      month: 'Mar',
      money: 69430,
    },
    {
      month: 'Apr',
      money: 56054,
    },
    {
      month: 'May',
      money: 65400,
    },
    {
      month: 'Jun',
      money: 75043,
    },
    {
      month: 'Jul',
      money: 60540,
    },
    {
      month: 'Aug',
      money: 75349,
    },
    {
      month: 'Sep',
      money: 60432,
    },
    {
      month: 'Oct',
      money: 64327,
    },
    {
      month: 'Nov',
      money: 60403,
    },
    {
      month: 'Dec',
      money: 70543,
    },
    
  ];
  const config = {
    data,
    xField: 'month',
    yField: 'money',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  return <Column style={{marginTop: 50}} {...config} />;
};

function App() {

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
  });

  const getHomeStatistic = async () => {
    const result = await axiosInstance.post('get/home/statistic');
    console.log(result);
  }

  useEffect(() => {
    getHomeStatistic();
  }, [])

  return (
    <div>
      {/* <Row gutter={[16, 16]} style={{marginBottom: 15}}>  
          <Col span={6}>
            <Card
              title={
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p>Продажи</p>
                  <ShoppingCartOutlined style={{fontSize: 20}} />
                </div>
              }
              style={{
                width: '100%'
              }}
            >
              <div className="cardInner">
                 <Statistic title="Обработанные заказы" value={14} />
                 <Statistic title="Необработанные заказы" value={5} />
                 <Statistic title="Неотправленные заказы" value={10} />
                 <Statistic title="Заказов в пути" value={24} />
                 <Statistic title="Отмененные заказы" value={2} />
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title={
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p>Склад</p>
                  <ShoppingCartOutlined style={{fontSize: 20}} />
                </div>
              }
              style={{
                width: '100%',
              }}
            >
              <div className="cardInner">
              <Statistic title="Розничная цена" value={350500} suffix='₺' />
              <Statistic title="Оптовая цена" value={230500} suffix='₺' />
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title={
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p>Сотрудники</p>
                  <ShoppingCartOutlined style={{fontSize: 20}} />
                </div>
              }
              style={{
                width: '100%',
              }}
            >
              <div className="cardInner">
                <Statistic title="Менеджер" value="Андрей" />
                <Statistic title="Продавец" value="Виталий" />
                <Statistic title="Курьер" value="Дарья" />
              </div>  
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title={
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p>Клиенты</p>
                  <ShoppingCartOutlined style={{fontSize: 20}} />
                </div>
              }
              style={{
                width: '100%',
              }}
            >
              <Statistic title="Новые" value={34} />
              <Statistic title="Старые" value={20} /> 
            </Card>
          </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
         <Card
              title={
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p>Расходы</p>
                  <ShoppingCartOutlined style={{fontSize: 20}} />
                </div>
              }
              style={{
                width: '100%',
              }}
            >
              <Statistic title="За Месяц" value={6030} suffix='₺' />
              <Statistic title="За сегодня" value={350} suffix='₺' /> 
          </Card>
        </Col>
      </Row> */}
            {/* <Row gutter={[16, 16]}>
        <Col span={6}>
            <Card>
              <Statistic
                title="Обработанные заказы"
                value={11}
                prefix={<ShoppingCartOutlined />}
              />
            </Card>
        </Col>
        <Col span={6}>
            <Card>
              <Statistic
                title="Необработанные заказы"
                value={11}
                prefix={<ShoppingCartOutlined />}
              />
            </Card>
        </Col>
        <Col span={6}>
            <Card>
              <Statistic
                title="Неотправленные заказы"
                value={11}
                prefix={<ShoppingCartOutlined />}
              />
            </Card>
        </Col>
        <Col span={6}>
            <Card>
              <Statistic
                title="Отмененные заказы"
                value={11}
                prefix={<ShoppingCartOutlined />}
              />
            </Card>
        </Col>
        <Col span={18}>
          <Card>
            <DemoColumn />
          </Card>
        </Col>
        <Col span={6}>
          <Card  
            title={
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p>Склад</p>
                <ShoppingCartOutlined style={{fontSize: 20}} />
              </div>
            }>
            <Statistic
              title="Розничная цена"
              value={353000}
              suffix='₺'
            />
            <Statistic
              title="Оптовая цена"
              value={353000}
              suffix='₺'
            />
          </Card>
        </Col>
        <Col span={6}>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card  
            title={
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p>Клиенты</p>
                <ShoppingCartOutlined style={{fontSize: 20}} />
              </div>
            }>
            <Statistic
              title="Новые"
              value={104}
            />
            <Statistic
              title="Старые"
              value={139}
            />
          </Card>
        </Col>
        <Col span={6}>
            <Card>
              <Statistic
                title="Обработанные заказы"
                value={11}
                precision={2}
                prefix={<ShoppingCartOutlined />}
              />
            </Card>
        </Col>
        <Col span={6}>
            <Card>
              <Statistic
                title="Обработанные заказы"
                value={11}
                precision={2}
                prefix={<ShoppingCartOutlined />}
              />
            </Card>
        </Col>
      </Row> */}

      {/* <div style={{display: 'flex', gap: 30}}>
        <Statistic title="Обработанные заказы" value={14} />
        <Statistic title="Необработанные заказы" value={5} />
        <Statistic title="Неотправленные заказы" value={10} />
        <Statistic title="Заказов в пути" value={24} />
        <Statistic title="Отмененные заказы" value={2} />
      </div> */}
    </div>
  );
}

export default App;

import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Drawer, Form, Alert, Input, Space, Select } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';

function NpAccountsPage() {
  
    const [open, setOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Error');
    const [npAccountsData, setNpAccountsData] = useState([]);
    const [editingAccount, setEditingAccount] = useState([]);
    const [cities, setCities] = useState([]);
    const [offices, setOffices] = useState([]);
    const [cityOptions, setCityOptions] = useState([ {DescriptionRu: undefined} ]);
    const [officeOptions, setOfficeOptions] = useState([ {DescriptionRu: undefined} ]);
    const [selectedCity, setSelectedCity] = useState([]);

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACK_URL,
    });

    const onClose = () => { 
        setOpen(false);
    };

    const onChange = (value) => {
      setSelectedCity(value)
      console.log(`selected ${value}`);
    };

    const getNovaposhtaData = async () => {
      const { data } = await axiosInstance.get('get/novaposhta');
      setCities(data.payload.cities);
      setOffices(data.payload.offices);
    };

    const onSearchCity = (value) => {
      const results = cities.filter(item => item.DescriptionRu.search(value) !== -1);
      setCityOptions(results)
    };

  const onSearchOffice = (value) => {
      const results = offices.filter(item => item.DescriptionRu.search(value) !== -1);
      const filtred = results.filter(item => item.SettlementRef === selectedCity);
      setOfficeOptions(filtred)
  };

    const submitNpAccountCreate = async (values) => {
        const { data } = await axiosInstance.post('create/novaposhta/account', values);
        if (data.status === 'ok') {
          setOpen(false);
          getNpAccounts();
        } else if (data.status === 'failed name') {
          setAlertMessage('This site name is already taken');
          setAlertStatus(true);
          setTimeout(() => {setAlertStatus(false)}, 5000);
        } else if (data.status === 'failed domain') {
          setAlertMessage('This site domain is already taken');
          setAlertStatus(true);
          setTimeout(() => {setAlertStatus(false)}, 5000);
        }   
    }

    const submitNpAccountEdit = async (values) => {
      await axiosInstance.post('edit/novaposhta/account', { 
        old: editingAccount, 
        new: { name: values.name, key: values.key, city: cityOptions[0].Ref, office: officeOptions[0].key } 
      });
      setOpen(false);
      getNpAccounts();
    }
    
    const getNpAccounts = async () => {
      const { data } = await axiosInstance.get('get/novaposhta/accounts');
      setNpAccountsData(data.payload);
    }

    const removeNpAccount = async (name) => {
     await axiosInstance.post('remove/novaposhta/account', { name });
     
      getNpAccounts();
    }

    const [form] = Form.useForm();

    const showDrawer = () => {
      setEditingAccount([])
      form.setFieldsValue({
        name: '',
        key: '',
        city: '',
        office: '',
      });
      setOpen(true);
    };

    const editNpAccount = async (name) => {

      const npAccount = npAccountsData.find((item) => item.name === name);

      const cityResult = cities.filter(item => item.Ref === npAccount.city);
      setCityOptions(cityResult)

      const officeResult = offices.filter(item => item.Ref === npAccount.office);
      setOfficeOptions(officeResult)


      setEditingAccount(npAccount);
      setOpen(true);
    }

    useEffect(() => {
      getNpAccounts();
      getNovaposhtaData();
    }, [])
    
    useEffect(() => {
      console.log(officeOptions)
      form.setFieldsValue({
          name: editingAccount.name,
          key: editingAccount.key,
          city: cityOptions[0].DescriptionRu,
          office: officeOptions[0].DescriptionRu,
      });
    }, [form, editingAccount]);

    
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        width: 30,
        render: (text, { name }) => 
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Button onClick={() => removeNpAccount(name)} danger type="primary" icon={<DeleteOutlined />} />
            <Button onClick={() => editNpAccount(name)} icon={<EditOutlined />} />
          </div>
        
      },
    ];


    return (
        <>
      <Button style={{ float: 'right', margin: '5px' }} type="primary" onClick={showDrawer} icon={<PlusOutlined />} />

      <Table dataSource={npAccountsData} columns={columns} />


      <Drawer
        title={ editingAccount.length !== 0 ? "Edit a account" : "Create a new account" }
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Form layout="vertical" onFinish={ editingAccount.length !== 0 ? submitNpAccountEdit : submitNpAccountCreate } form={form}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter user name',
                  },
                ]}
              >
                <Input placeholder="Please enter novaposhta aaccount key" />
              </Form.Item>
              <Form.Item
                name="key"
                label="Key"
                rules={[
                  {
                    required: true,
                    message: 'Please enter novaposhta key',
                  },
                ]}
              >
                <Input placeholder="Please enter novaposhta key" />
              </Form.Item>
              <Form.Item 
                name="city" 
                label="City"
                rules={[
                  {
                    required: true,
                    message: 'Please select city',
                  },
                ]}
              >
              <Select
                  showSearch
                  placeholder="Select a city"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearchCity}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={(cityOptions || []).map((d) => ({
                      value: d.Ref,
                      label: d.DescriptionRu,
                    }))}
              />
              </Form.Item>
              <Form.Item 
                name="office" 
                label="Post office"
                rules={[
                  {
                    required: true,
                    message: 'Please select post office',
                  },
                ]}
              >
              <Select
                  showSearch
                  placeholder="Select a person"
                  optionFilterProp="children"
                  onSearch={onSearchOffice}
                  filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={(officeOptions || []).map((d) => ({
                      value: d.Ref,
                      label: d.DescriptionRu,
                  }))}
              />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">{ editingAccount.length !== 0 ? "Edit" : "Create"}</Button>
              </Form.Item>
              <Alert
                message="Error"
                description={alertMessage}
                type="error"
                showIcon
                style={{display: alertStatus ? 'block' : 'none'}}
              />
        </Form>
      </Drawer>
    </>
    )
}

export default NpAccountsPage;
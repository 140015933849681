/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import update from 'react-addons-update';
import { 
  CHANGE_THEME, 
  CHANGE_LANG,
} from './types';

// The initial state of the App
export const initialState = {
  theme: 'white',
  lang: 'en'
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_THEME:
        return update(state, {
          theme: { $set: action.payload.theme },
        });
        break;

      case CHANGE_LANG:
        return update(state, {
          lang: { $set: action.payload.value },
        });
        break;

    }

    return state;
  };

export default reducer;

import React, { useRef, useState, useEffect } from 'react';
import { SearchOutlined, DeleteOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Button, Table, Col, Row, Image, Space, Divider } from 'antd';
import { useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import placeholder from '../../images/placeholder.jpg';

import OrderInfoFrom from '../../components/EditOrderForm/OrderInfoForm';

const formItemLayout = {
  labelCol: { span: 24 },
}

const App = () => {

  let { stocks } = useSelector((state) => state.auth);

  let { id } = useParams();

  const { t, i18n } = useTranslation();

  const [choosedProducts, setProduct] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [summary, setSummary] = useState([]);
  const [deliveryType, setDeliveryType] = useState([]);
  const [oldOrder, setOldOrder] = useState([]);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
  });

  let themeSettings = useSelector((state) => state.theme);

  const onFinish = async (values) => {
    await axiosInstance.post('edit/order', { oldData: oldOrder, newData: values });
    // navigate('../../orders');
  };


  const getOrder = async () => {
    const { data } = await axiosInstance.post('get/order', { id });
    const order = data.payload;

    console.log(order)
    setOldOrder(order._id);
    const choosed = order.products.map(item => ({ 
      names: item._id.names, 
      _id: item._id._id,
      images: item._id.images,
      sku: item._id.sku,
      color: item._id.color,
      size: item._id.size,
      category: item._id.category,
      price: item.price,
      quantity: item.quantity,
    }))
    setProduct(choosed);
    setDeliveryType(order.deliveryType)

    let orderData = {
        site: order.site._id,
        stock: order.stock._id,
        deliveryType: order.deliveryType,
        paymentType: order.paymentType,
        orderStatus: order.orderStatus,
        paymentStatus: order.paymentStatus,
        fullname: order.client.name,
        telephone: order.client.phone,
        email: order.client.email,
        discount: order.discount,
        comment: order.comment,
    }
    if (order.npDelivery) {
      const npDelivery = {
        npAccount: order.npDelivery && order.npDelivery.npAccount !== null ? order.npDelivery.npAccount._id : undefined,
        weight: order.npDelivery.weight,
        assessedValue: order.npDelivery.assessedValue,
        ttn: order.npDelivery.ttn,
        deliveryPayer: order.npDelivery.deliveryPayer,
        city: order.npDelivery.city.name,
        postOffice: order.npDelivery.postOffice.name,
        deliveryPayer: order.npDelivery.deliveryPayer,
      }
      orderData = {...orderData, ...npDelivery}
    }

    form.setFieldsValue(orderData);
  } 

  const getProducts = async () => { 
    const products = await axiosInstance.post('get/products', { stocks });
    setProductsData(products.data.payload);
    
  }
  
  const addProduct = (_id, quantity = 0) => {

    const addProduct = productsData.find(item => item._id == _id)
    
    let added = false;

    let changedProducts;

    changedProducts = choosedProducts.map(item => {
      if (item._id === _id) {
        added = true
        console.log(quantity)

        if (quantity || quantity === null) {
          return { ...item, quantity };
        }

        return { ...item, quantity: item.quantity + 1 };
      } else {
        return item;
      }
    });

    setProduct(changedProducts);

    if (added) return;

    setProduct(current => [...current, { ...addProduct, quantity: 1 }]);
  }

  const removeProduct = (_id) => {
    const products = choosedProducts.filter((item, index) => item._id !== _id);
    setProduct(products);
  }

  const changeQuantity = (value, _id) => {
    choosedProducts.forEach(item => {
      if (item._id === _id) item.quantity = value
    })
    productsSummary();
  }

  const changePrice = (value, _id) => {
    choosedProducts.forEach(item => {
      if (item._id === _id) item.price = value
    })
    productsSummary();
  }

  const productsSummary = () => {
      let sum = 0;
      choosedProducts.forEach(item => {
        sum += item.quantity * item.price
      })
      form.setFieldsValue({
        summary: sum,
        assessedValue: sum,
    });
    setSummary(sum);
  }


  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

    const columnsProducts = [
      { 
        title: t('image'), 
        key: 'images',
        render: (text, { images }) => <div className="productImages">
          <Image.PreviewGroup>
            {
              images && images.length > 0 ?
              images.map((image, key) => 
                <Image key={key} className="productImage" width={70} src={process.env.REACT_APP_BACK_URL + 'uploads/' + image.url} />
              )
              :
              <Image className="productImage" width={70} src={placeholder} />
            }
          </Image.PreviewGroup>
        </div>
      },  
      { 
        title: t('name'),
        dataIndex: ['names', themeSettings.lang],
        key: 'names', 
        ...getColumnSearchProps(['names', themeSettings.lang]),
      },  
      {
        title: t('quantity'),
        dataIndex: 'quantity',
        key: 'quantity',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.quantity - b.quantity,
        render: (text) => <>{ text } pcs.</>
      },
      {
        title: t('price'),
        dataIndex: 'price',
        key: 'price',
        sorter: (a, b) => a.price - b.price,
        render: (text) => <>{ text } ₺</>
      },
      {
        title: t('sku'),
        dataIndex: 'sku',
        key: 'sku',
        ...getColumnSearchProps('sku'),
      },
      {
        title: t('color'),
        dataIndex: ['color', 'name'],
        key: 'color',
        render: (text, { color }) => color ?
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>{text} <div className='colorCube' style={{backgroundColor: color.hex}}></div></div>
        :
        <></>
      },
      {
        title: t('size'),
        dataIndex: ['size', 'name'],
        key: 'size',
      },
      // {
      //   title: t('model'),
      //   dataIndex: 'model',
      //   key: 'model',
      // },
      {
        title: t('category'),
        dataIndex: ['category', 'name'],
        key: 'category',
      },
      {
        width: 30,
        render: (text, { _id, quantity }) => <>
          { quantity > 0 ? 
            <Button onClick={() => addProduct(_id)} icon={<PlusOutlined />} /> : 
            <Button danger icon={<InboxOutlined />} />
          }
        </>
      },
    ];

    const columnsChoosed = [
      { 
        title: t('image'), 
        key: 'images',
        render: (text, { images }) => <div className="productImages">
          <Image.PreviewGroup>
            {
              images && images.length > 0 ?
              images.map((image, key) => 
                <Image key={key} className="productImage" width={70} src={process.env.REACT_APP_BACK_URL + 'uploads/' + image.url} />
              )
              :
              <Image className="productImage" width={70} src={placeholder} />
            }
          </Image.PreviewGroup>
        </div>
      },  
      { 
        title: t('name'),
        dataIndex: ['names', themeSettings.lang],
        key: 'names', 
        ...getColumnSearchProps(['names', themeSettings.lang]),
      },  
      {
        title: t('quantity'),
        dataIndex: 'quantity',
        key: 'quantity',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.quantity - b.quantity,
        render: (text, {_id}) => <InputNumber onChange={(v) => {addProduct(_id, v)}} value={text} />
      },
      {
        title: t('price'),
        dataIndex: 'price',
        key: 'price',
        sorter: (a, b) => a.price - b.price,
        render: (text) => <>{ text } ₺</>
      },
      {
        title: t('sku'),
        dataIndex: 'sku',
        key: 'sku',
        ...getColumnSearchProps('sku'),
      },
      {
        title: t('color'),
        dataIndex: ['color', 'name'],
        key: 'color',
        render: (text, { color }) => color ?
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>{text} <div className='colorCube' style={{backgroundColor: color.hex}}></div></div>
        :
        <></>
      },
      {
        title: t('size'),
        dataIndex: ['size', 'name'],
        key: 'size',
      },
      {
        title: t('category'),
        dataIndex: ['category', 'name'],
        key: 'category',
      },
      {
        width: 30,
        render: (text, { names, _id, price, quantity }) => <>
          { quantity > 0 ? 
            <Button type="primary" danger onClick={() => removeProduct(_id)} icon={<DeleteOutlined />} />  : 
            <Button danger icon={<InboxOutlined />} />
          }
        </>
      },
    ];


  useEffect(() => {
    form.setFieldsValue({
        products: choosedProducts,
    });
    productsSummary();
  }, [form, choosedProducts]);

  useEffect(() => {
    getProducts();
    getOrder();
  }, []);


  return (
    <>
    <Table columns={columnsProducts} dataSource={(productsData || []).map((item, key) => ({
        _id: item._id,
        names: item.names,
        price: item.price,
        quantity: item.quantity,
        sku: item.sku,
        model: item.model,
        category: item.category,
        images: item.images,
        size: item.size,
        color: item.color,
        updatedAt: item.updatedAt,
        createdAt: item.createdAt,  
        key,
      }))}
      style={{
        height: 240,
        overflow: 'auto'
      }} />

    <Form
      form={form}
      layout='inline'
      onFinish={onFinish}
      initialValues={{products: choosedProducts}}
      {...formItemLayout}
    >  
      <Row style={{width: '100%'}}>
        <Col xs={{span: 24,}}sm={{span: 12,}} flex="1 1 300px"  style={{padding: '0 10px'}}>
          <Divider orientation="left" orientationMargin="0">{t('orderProducts')}</Divider>
          {/* { choosedProducts.length === 0 ? <div className="emptyProducts"><p>{t('empty')}</p></div> : 
            choosedProducts.map((item, index) => 
              <Form.Item key={index} className="formItemProduct">
                <p className="productName">{item.names[themeSettings.lang]}</p>
                <div className="productButtons">
                  <Form.Item name={['products', index, '_id']} style={{position: 'absolute'}}>
                    <Input type="hidden" />
                  </Form.Item> 
                  <Form.Item name={['products', index, 'quantity']}>
                    <InputNumber onChange={value => {changeQuantity(value, item._id)}} min={1} max={100} style={{width: '70px'}} />
                  </Form.Item>
                  <Form.Item name={['products', index, 'price']}>
                    <InputNumber min={1} onChange={value => {changePrice(value, item._id)}} addonAfter='₺' style={{width: '120px'}} />
                  </Form.Item>
                  <Button type="primary" danger onClick={() => removeProduct(index)} icon={<DeleteOutlined />} /> 
                </div>
              </Form.Item>
          )} */}
          <Table 
            columns={columnsChoosed} dataSource={(choosedProducts || [])}         
            style={{
              height: 240,
              overflow: 'auto'
            }}   
          />
        </Col>
      <Col xs={{span: 24,}}sm={{span: 12,}} flex="1 1 300px"  style={{padding: '0 10px'}}>
          <OrderInfoFrom form={form} summary={summary} deliveryType={deliveryType} />
      </Col>
    </Row>
   </Form>
    </>
  );
};
export default App;
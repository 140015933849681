import { SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined, CopyOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { Button, Table, Image, Upload,  Drawer, Checkbox, Row, Col, Form, Alert, Input, Space, Select, InputNumber, Popconfirm, Divider  } from 'antd';
import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import { useTranslation } from 'react-i18next';
import get from "lodash.get";
import isequal from "lodash.isequal";
import placeholder from '../../images/placeholder.jpg';
import ruImage from '../../images/ru.png';
import enImage from '../../images/en.png';
import trImage from '../../images/tr.png';

function ProductsPage() {
  
    const [open, setOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMessage, setAlertMessage] = useState('Error');
    const [sitesData, setSitesData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [editingProduct, setEditingProduct] = useState(undefined);
    const [settingsData, setSettingsData] = useState({ colors: [], sizes: [] });
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editingAttr, setEditingAttr] = useState({ _id: null, type: null });

    const [checkedListSize, setCheckedListSize] = useState();
    const [indeterminateSize, setIndeterminateSize] = useState(true);
    const [checkAllSize, setCheckAllSize] = useState(false);

    const [checkedListColor, setCheckedListColor] = useState();
    const [indeterminateColor, setIndeterminateColor] = useState(true);
    const [checkAllColor, setCheckAllColor] = useState(false);

    let { sites, stocks, cashregisters } = useSelector((state) => state.auth);

    const { t, i18n } = useTranslation();

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACK_URL,
    });

    const navigate = useNavigate();
    
    let themeSettings = useSelector((state) => state.theme);

    const onClose = () => {
        setOpen(false); 
    };

    const submitProductEdit = async (values) => {
      setLoading(true); 
      console.log(fileList)
      if (fileList.length > 0) {
        console.log(fileList)
        values.images = fileList.map(item => ({ url: item.response.filename }));
      }
      values.names = { ru: values.ru, en: values.en, tr: values.tr };
      const result = await axiosInstance.post('edit/product', { old: editingProduct, new: values, stocks, sites }).catch(function (error) {
        if (error.response) {
          console.log('Error', error.message);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
        setLoading(false)
      });
      if (result.data.status == 'ok') setLoading(false)
      setOpen(false);
      getProducts();
    }

    const submitProductCreate = async (values) => {
      setLoading(true);
      values.names = { ru: values.ru, en: values.en, tr: values.tr };
      values.images = fileList.map(item => ({ url: item.response.filename }));
      values.stocks = stocks;
      values.sites = sites;
      const result = await axiosInstance.post('create/product', values).catch(function (error) {
        if (error.response) {
          console.log('Error', error.message);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
        setLoading(false)
      });
      if (result.data.status == 'ok') setLoading(false)
      setOpen(false);
      getProducts();
    }
     
    const getSites = async () => {
      const sites = await axiosInstance.get('get/sites');
      setSitesData(sites.data.payload);
      
    } 

    const getProducts = async () => {
      const products = await axiosInstance.post('get/products', { stocks, lang: themeSettings.lang });

      const nameComp = (a, b) => a.names[themeSettings.lang] - b.names[themeSettings.lang];
      const colorComp = (a, b) => {
        if (a.color && b.color) {
          return a.color.name - b.color.name;
        }
      };

      function multipleSort(compFunctions) {
          return function (a, b) {
              for (const func of compFunctions) {
                  const result = func(a, b);
                  if (result !== 0) {
                      return result;
                  }
              }
          }
      }

      const sortedProducts = products.data.payload.sort(multipleSort([nameComp, colorComp]));
      setProductsData(products.data.payload);
    }

    const removeProduct = async (_id) => {
      await axiosInstance.post('remove/product', { _id });
      getProducts();
    }

    const getCategories = async () => {
      const categories = await axiosInstance.get('get/categories');
      setCategories(categories.data.payload);
    }

    const [form] = Form.useForm();

    const editProduct = async (_id) => {
      setEditingProduct(productsData.find((item) => item._id === _id));
      setOpen(true);
    }

    const getSettings = async () => { 
      let colors = await axiosInstance.get('get/colors');
      let sizes = await axiosInstance.get('get/sizes');
      
      sizes = sizes.data.payload.map(item => item.name);
      colors = colors.data.payload.map(item => ({ name: item.name, hex: item.hex }))

      setSettingsData({ sizes, colors });
    }

    const createProduct = async () => {
      form.setFieldsValue({
        ru: '',
        en: '',
        tr: '',
        model: '',
        price: '',
        quantity: '',
        sku: '',
        colors: '',
        sizes: '',
        category: '',
        site: '',
      });
      setFileList([])
      setEditingProduct(undefined);
      setOpen(true);
    }

    useEffect(() => {
      if (editingProduct === undefined) return; 
      form.setFieldsValue({
        _id: editingProduct._id,
        ru: editingProduct.names.ru,
        en: editingProduct.names.en,
        tr: editingProduct.names.tr,
        model: editingProduct.model,
        wholeSalePrice: editingProduct.wholeSalePrice,
        colors: [editingProduct.color ? editingProduct.color.name : ''],
        sizes: [editingProduct.size ? editingProduct.size.name : ''],
        price: editingProduct.price,
        quantity: editingProduct.quantity,
        sku: editingProduct.sku,
        category: editingProduct.category._id,
        site: editingProduct.site._id,
      });

      setFileList(editingProduct.images.map(image => ({
          url: process.env.REACT_APP_BACK_URL + 'uploads/' + image.url,
          response: { filename: image.url },
          status: 'done',
      })))
    }, [editingProduct])

    useEffect(() => {
      getSites();
      getProducts();
      getSettings();
      getCategories();
    }, [])

    
    const [fileList, setFileList] = useState([]);
    const onChange = ({ fileList: newFileList }) => {
      setFileList(newFileList);
    }; 

    const copyProduct = async (_id) => {
      const { data } = await axiosInstance.post('copy/product', { _id });
      console.log(data.payload)

      let tempProducts = [];

      productsData.forEach(product => {
        if (product._id !== _id) {
          tempProducts.push(product);
        } else {
          tempProducts.push(product);
          tempProducts.push(data.payload);
        }
      })
      setProductsData(tempProducts)
    }

    const onPreview = async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    };
 

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
  
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    
      render: (text) =>
        isequal(searchedColumn, dataIndex)  ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });

    const columns = [ 
      { 
        title: t('image'), 
        key: 'images',
        render: (text, { images }) => <div className="productImages">
          <Image.PreviewGroup>
            {
              images && images.length > 0 ?
              images.map((image, key) => 
                <Image key={key} className="productImage" width={70} src={process.env.REACT_APP_BACK_URL + 'uploads/' + image.url} />
              )
              :
              <Image className="productImage" width={70} src={placeholder} />
            }
          </Image.PreviewGroup>
        </div>
      },  
      { 
        title: t('name'),
        dataIndex: ['names', themeSettings.lang],
        key: 'names', 
        // sorter: (a, b) => a.names[themeSettings.lang].localeCompare(b.names[themeSettings.lang]),
        // defaultSortOrder: 'ascend',
        ...getColumnSearchProps(['names', themeSettings.lang]),
        render: (text, {_id}) => 
          <Form onFinish={(values) => {
              setEditingAttr({_id, type:'name'});
              if (editingAttr._id === _id && editingAttr.type === 'name' && values.names) {
                console.log(values)
                setEditingAttr({_id: null, type: null})
                axiosInstance.post('change/product', { values, _id });
                getProducts()
              }
          }} style={{display: 'flex', justifyContent: 'space-between'}}>
            { 
              editingAttr._id === _id && editingAttr.type === 'name' 
              ?
                <Form.Item name={['names', themeSettings.lang]} style={{marginBottom: 0}}><Input defaultValue={text} /></Form.Item>
              : 
                <>{text}</> 
            }
            <div style={{display: 'flex', gap: 5}}>
              <Button style={{display: editingAttr._id === _id && editingAttr.type === 'name' ? 'block' : "none"}} icon={<CloseOutlined />} danger onClick={() => {setEditingAttr({_id: null, type: null});}} />
              <Button 
                type="text"
                htmlType="submit" 
                icon={
                  editingAttr._id === _id && editingAttr.type === 'name' ? 
                  <CheckOutlined /> :
                  <EditOutlined />
                } 
              />
            </div>
          </Form>
      },  
      {
        title: t('price'),
        dataIndex: 'price',
        key: 'price',
        width: 100,
        render: (text, {_id}) => 
          <Form onFinish={(values) => {
              setEditingAttr({_id, type:'price'});
              if (editingAttr._id === _id && editingAttr.type === 'price' && values.price) {
                console.log(values)
                setEditingAttr({_id: null, type: null})
                axiosInstance.post('change/product', { values, _id });
                getProducts()
              }
          }} style={{display: 'flex', justifyContent: 'space-between'}}>
            { 
              editingAttr._id === _id && editingAttr.type === 'price' 
              ?
                <Form.Item name='price' style={{marginBottom: 0}}><InputNumber defaultValue={text} /></Form.Item>
              : 
                <>{text} ₺</> 
            }
            <div style={{display: 'flex', gap: 5}}>
              <Button style={{display: editingAttr._id === _id && editingAttr.type === 'price' ? 'block' : "none"}} icon={<CloseOutlined />} danger onClick={() => {setEditingAttr({_id: null, type: null});}} />
              <Button 
                type="text"
                htmlType="submit" 
                icon={
                  editingAttr._id === _id && editingAttr.type === 'price' ? 
                  <CheckOutlined /> :
                  <EditOutlined />
                } 
              />
            </div>
          </Form>
      },
      {
        title: t('wholeSalePrice'),
        dataIndex: 'wholeSalePrice',
        key: 'wholeSalePrice',
        width: 100,
        render: (text, {_id}) => 
        <Form onFinish={(values) => {
            setEditingAttr({_id, type:'wholeSalePrice'});
            if (editingAttr._id === _id && editingAttr.type === 'wholeSalePrice' && values.wholeSalePrice) {
              console.log(values)
              setEditingAttr({_id: null, type: null})
              axiosInstance.post('change/product', { values, _id });
              getProducts()
            }
        }} style={{display: 'flex', justifyContent: 'space-between'}}>
          { 
            editingAttr._id === _id && editingAttr.type === 'wholeSalePrice' 
            ?
              <Form.Item name='wholeSalePrice' style={{marginBottom: 0}}><InputNumber defaultValue={text} /></Form.Item>
            : 
              <>{text} ₺</> 
          }
          <div style={{display: 'flex', gap: 5}}>
            <Button style={{display: editingAttr._id === _id && editingAttr.type === 'wholeSalePrice' ? 'block' : "none"}} icon={<CloseOutlined />} danger onClick={() => {setEditingAttr({_id: null, type: null});}} />
            <Button 
              type="text"
              htmlType="submit" 
              icon={
                editingAttr._id === _id && editingAttr.type === 'wholeSalePrice' ? 
                <CheckOutlined /> :
                <EditOutlined />
              } 
            />
          </div>
        </Form>
      },
      {
        title: t('quantity'),
        dataIndex: 'quantity',
        key: 'quantity',
        // defaultSortOrder: 'descend',
        // sorter: (a, b) => a.quantity - b.quantity,
        render: (text) => <p>{ text } pcs.</p>
      },
      // {
      //   title: t('reserve'),
      //   dataIndex: 'reserved',
      //   key: 'reserved',
      //   defaultSortOrder: 'descend',
      //   sorter: (a, b) => a.quantity - b.quantity,
      //   render: (text) => <p>{ text } pcs.</p>
      // },
      {
        title: t('sku'),
        dataIndex: 'sku',
        key: 'sku',
        ...getColumnSearchProps('sku'),
        render: (text, {_id}) => 
          <Form onFinish={(values) => {
              setEditingAttr({_id, type:'sku'});
              if (editingAttr._id === _id && editingAttr.type === 'sku' && values.sku) {
                console.log(values)
                setEditingAttr({_id: null, type: null})
                axiosInstance.post('change/product', { values, _id });
                getProducts()
              }
          }} style={{display: 'flex', justifyContent: 'space-between'}}>
            { 
              editingAttr._id === _id && editingAttr.type === 'sku' 
              ?
                <Form.Item name='sku' style={{marginBottom: 0}}><Input defaultValue={text} /></Form.Item>
              : 
                <>{text}</> 
            }
            <div style={{display: 'flex', gap: 5}}>
              <Button style={{display: editingAttr._id === _id && editingAttr.type === 'sku' ? 'block' : "none"}} icon={<CloseOutlined />} danger onClick={() => {setEditingAttr({_id: null, type: null});}} />
              <Button 
                type="text"
                htmlType="submit" 
                icon={
                  editingAttr._id === _id && editingAttr.type === 'sku' ? 
                  <CheckOutlined /> :
                  <EditOutlined />
                } 
              />
            </div>
          </Form>
      },
      {
        title: t('color'),
        dataIndex: ['color', 'name'],
        key: 'color',
        // sorter: (a, b) => a.names[themeSettings.lang].localeCompare(b.names[themeSettings.lang]), 
        // defaultSortOrder: 'ascend',

        render: (text, {_id, color}) => 
          <Form onFinish={(values) => {
              setEditingAttr({_id, type:'color'});
              if (editingAttr._id === _id && editingAttr.type === 'color' && values.color) {
                console.log(values)
                setEditingAttr({_id: null, type: null})
                console.log(values)
                axiosInstance.post('change/product', { values, _id });
                getProducts()
              }
          }} style={{display: 'flex', justifyContent: 'space-between'}}>
            { 
              editingAttr._id === _id && editingAttr.type === 'color' 
              ?
                <Form.Item name='color' style={{marginBottom: 0}}>
                  <Select
                    placeholder="Select a color"
                    defaultValue={text}
                    options={settingsData.colors.map(color => ({
                      value: color.name,
                      label: color.name
                    }))}
                  />
                </Form.Item>
              : 
              color ?
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>{text} <div className='colorCube' style={{backgroundColor: color.hex}}></div></div>
              :
              <></> 
            }
            <div style={{display: 'flex', gap: 5}}>
              <Button style={{display: editingAttr._id === _id && editingAttr.type === 'color' ? 'block' : "none"}} icon={<CloseOutlined />} danger onClick={() => {setEditingAttr({_id: null, type: null});}} />
              <Button 
                type="text"
                htmlType="submit" 
                icon={
                  editingAttr._id === _id && editingAttr.type === 'color' ? 
                  <CheckOutlined /> :
                  <EditOutlined />
                } 
              />
            </div>
          </Form>
      },
      {
        title: t('size'),
        dataIndex: ['size', 'name'],
        key: 'size',
        filters: settingsData.sizes.map(item => ({
          text: item,
          value: item
        })),
        onFilter: (value, record) => record.size ? record.size.name === value : [],
        filterSearch: true,
        render: (text, { _id }) => 
          <Form onFinish={(values) => {
              setEditingAttr({_id, type:'size'});
              if (editingAttr._id === _id && editingAttr.type === 'size' && values.size) {
                setEditingAttr({_id: null, type: null})
                axiosInstance.post('change/product', { values, _id });
                getProducts()
              }
          }} style={{display: 'flex', justifyContent: 'space-between'}}>
            { 
              editingAttr._id === _id && editingAttr.type === 'size' 
              ?
                <Form.Item name='size' style={{marginBottom: 0}}>
                  <Select
                    placeholder="Select a size"
                    defaultValue={text}
                    options={settingsData.sizes.map(size => ({
                      value: size,
                      label: size
                    }))}
                  />
                </Form.Item>
              : 
              <>{text}</>
            }
            <div style={{display: 'flex', gap: 5}}>
              <Button style={{display: editingAttr._id === _id && editingAttr.type === 'size' ? 'block' : "none"}} icon={<CloseOutlined />} danger onClick={() => {setEditingAttr({_id: null, type: null});}} />
              <Button 
                type="text" 
                htmlType="submit" 
                icon={
                  editingAttr._id === _id && editingAttr.type === 'size' ? 
                  <CheckOutlined /> :
                  <EditOutlined />
                } 
              />
            </div>
          </Form>
      },
      // {
      //   title: t('model'),
      //   dataIndex: 'model',
      //   key: 'model',
      // },
      {
        title: t('category'),
        dataIndex: ['category', 'name'],
        key: 'category',
        filters: categories.map(item => ({
          text: item.name,
          value: item.name
        })),
        onFilter: (value, record) => record.category.name.startsWith(value),
        filterSearch: true,
      },
      {
        title: t('updated'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text) => <>{moment(text).format("DD.MM.YYYY HH:mm:ss")}</>
      },
      // {
      //   title: t('created'),
      //   dataIndex: 'createdAt',
      //   key: 'createdAt',
      //   render: (text) => <>{moment(text).format("DD.MM.YYYY HH:mm:ss")}</>
      // },
      {
        width: '50px',
        render: (text, { _id }) => 
          <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
            <Button icon={<EditOutlined />} onClick={() => editProduct(_id)} />
            <Button type='primary' icon={<CopyOutlined />} onClick={() => copyProduct(_id)} />
            <Popconfirm
              placement="leftBottom"
              title={t('wantToRemove')}
              onConfirm={() => removeProduct(_id)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Button type='primary' danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        
      },
    ];


    return (
      <>
      {/* <Button style={{float: 'right', margin: '5px 5px'}} type="primary" onClick={() => {navigate('../accept/products')}} icon={<PlusOutlined />} /> */}
      <Button style={{float: 'right', margin: '5px 5px'}} type="primary" onClick={() => {createProduct()}} icon={<PlusOutlined />}>{ t('create') }</Button>

      <Table dataSource={(productsData || []).map(item => ({ 
        _id: item._id,
        names: item.names,
        price: item.price,
        quantity: item.quantity,
        sku: item.sku,
        model: item.model,
        category: item.category,
        images: item.images,
        size: item.size,
        wholeSalePrice: item.wholeSalePrice,
        color: item.color,
        updatedAt: item.updatedAt,
        createdAt: item.createdAt,
      }))} 
      columns={columns}
      scroll={{
        x: 1300,
      }}
      />


      <Drawer
        title={ editingProduct !== undefined ? t('edit') : t('create') }
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>{ t('cancel') }</Button>
          </Space>
        }
      >
        <Form layout="vertical" onFinish={ editingProduct !== undefined ? submitProductEdit : submitProductCreate } form={form}>
              <Form.Item
                name="ru"
                label={<>{t('name')} <img style={{marginLeft: 5}} src={ruImage} /></>}
              >
                <Input style={{width: '100%'}} />
              </Form.Item>
              <Form.Item
                name="en"
                label={<>{t('name')} <img style={{marginLeft: 5}} src={enImage} /></>}
              >
                <Input style={{width: '100%'}} />
              </Form.Item>
              <Form.Item
                name="tr"
                label={<>{t('name')} <img style={{marginLeft: 5}} src={trImage} /></>}
              >
                <Input style={{width: '100%'}} />
              </Form.Item>
              <Form.Item
                name="price"
                label={t('price')}
                rules={[{required: true,message: 'Please enter price'}]}
              >
                <InputNumber style={{width: '100%'}} />
              </Form.Item>
              <Form.Item
                name="wholeSalePrice"
                label={t('wholeSalePrice')}
              >
                <InputNumber style={{width: '100%'}} />
              </Form.Item>
              <Form.Item
                name="sku"
                label={t('sku')}
                rules={[{required: true,message: 'Please enter sku'}]}
              >
                <Input style={{width: '100%'}} />
              </Form.Item>
              <Form.Item
                name="category"
                label={t('category')}
                rules={[{required: true,message: 'Please enter category'}]}
              >
                <Select
                    showSearch
                    placeholder="Select a category"
                    options={(categories || []).map((d) => ({
                        value: d._id,
                        label: d.name,
                      }))}
                />
              </Form.Item>
              <Form.Item
                label={t('size')}
              >
                {/* <Checkbox indeterminate={indeterminateSize} onChange={e => {
                  setCheckedListSize(e.target.checked ? settingsData.sizes : []);
                  setIndeterminateSize(false);
                  setCheckAllSize(e.target.checked);
                }} checked={checkAllSize}>
                  Check all
                </Checkbox> */}
                <Form.Item name='sizes'>
                  <Checkbox.Group value={checkedListSize} onChange={list => {
                    setCheckedListSize(list);
                    setIndeterminateSize(!!list.length && list.length < settingsData.sizes.length);
                    setCheckAllSize(list.length === settingsData.sizes.length);
                  }}>
                    <Row>
                      {settingsData.sizes.map(item => {
                        if (['OverSize', '38', '44'].includes(item)) {
                          return(
                          <>
                              <Checkbox value={item} style={{ lineHeight: '32px' }}>
                                {item}
                              </Checkbox>
                              <Divider plain style={{margin: '10px 0'}} />
                          </>  
                          )                          
                        }
                        return(<Col span={4}>
                          <Checkbox value={item} style={{ lineHeight: '32px' }}>
                            {item}
                          </Checkbox>
                        </Col>)
                      })}
                    </Row>
                  </Checkbox.Group> 
                </Form.Item>
              </Form.Item> 

              <Form.Item
                label={t('color')}
              >
              {/* <Checkbox indeterminate={indeterminateColor} onChange={e => {
                setCheckedListColor(e.target.checked ? settingsData.colors.map(item => item.name) : []);
                setIndeterminateColor(false);
                setCheckAllColor(e.target.checked);
              }} checked={checkAllColor}>
                Check all
              </Checkbox> */}
              <Form.Item name='colors'>
                <Checkbox.Group value={checkedListColor} onChange={list => {
                  setCheckedListColor(list);
                  setIndeterminateColor(!!list.length && list.length < settingsData.colors.length);
                  setCheckAllColor(list.length === settingsData.colors.length);
                }}>
                  { settingsData.colors.map((item, key) => <Checkbox key={key} value={item.name}>
                    <div style={{display: 'flex', gap: 5, alignItems: 'center'}}>{item.name} <div className="colorCube" style={{backgroundColor: item.hex}}></div></div>
                  </Checkbox>) }
                </Checkbox.Group> 
              </Form.Item>
              </Form.Item>




              <Form.Item label={t('upload')} name="file">
              <ImgCrop rotate>
                <Upload
                  action={ process.env.REACT_APP_BACK_URL + 'upload' }
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                >
                  {fileList.length < 5 && t('upload')}
                </Upload>
              </ImgCrop>
              </Form.Item>
              <Form.Item>
                <Button disabled={loading} htmlType="submit" type="primary">{ editingProduct !== undefined ? t('edit') : t('create') }</Button>
              </Form.Item> 
              <Alert
                message="Error"
                description={alertMessage}
                type="error"
                showIcon
                style={{display: alertStatus ? 'block' : 'none'}}
              />
        </Form>
      </Drawer>
    </>
    )
}

export default ProductsPage
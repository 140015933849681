import { PlusOutlined, SyncOutlined, EyeOutlined, EditOutlined, InfoCircleOutlined, DeleteOutlined, PrinterOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { Button, Table, Select, Tabs, Popover } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function OrdersPage() {

    const { t, i18n } = useTranslation();

    const [orders, setOrders] = useState([]);
    const [ordersCount, setOrdersCount] = useState({
      all: 0,
      notConfirmed: 0,
      onAssembly: 0,
      awaitingDelivery: 0,
      onTheWay: 0,
      delivered: 0,
      money: 0,
      completed: 0,
      removed: 0,
      returned: 0
    });
    const [tabOrders, setTabOrders] = useState([]);
    const [selectedTab, setSelectedTab] = useState('all');
    const [sites, setSites] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [npAccounts, setNpAccounts] = useState([]);
    const navigate = useNavigate();

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACK_URL,
    });
    
    const getOrders = async () => {
      // await axiosInstance.post('update/orders/statuses'); 
      const orders = await axiosInstance.get('get/orders');  
      setOrdersCount(orders.data.payload.ordersCount);
      setOrders(orders.data.payload.ordersData);    
    }

    const orderStatusFilter = async (value) => {
      setSelectedTab(value)
      setTabOrders(orders[value]);
    }

    const getSites = async () => {
      const sites = await axiosInstance.get('get/sites');
      setSites(sites.data.payload);      
    }

    const getNpAccounts = async () => {
      const accounts = await axiosInstance.get('get/novaposhta/accounts');
      setNpAccounts(accounts.data.payload);
    }

    const changeNpAccount = async (npAccount, _id) => {
      await axiosInstance.post('change/novaposhta/account', { npAccount, _id });
      getOrders();
    }

    const changePaymentStatus = async (paymentStatus, _id) => {
      await axiosInstance.post('change/order/payment/status', { paymentStatus, _id });
      getOrders();
    }

    const changeOrderStatus = async (orderStatus, _id) => {
      await axiosInstance.post('change/order/status', { orderStatus, _id });
      getOrders();
    }

    const changeDelvieryStatus = async (deliveryStatus, _id) => {
      await axiosInstance.post('change/order/delivery/status', { deliveryStatus, _id });
      getOrders();
    }
    
    const changePaymentType = async (paymentType, _id) => {
      await axiosInstance.post('change/order/payment/type', { paymentType, _id });
      getOrders();
    } 

    const getStocks = async () => {
      const stocks = await axiosInstance.get('get/stocks');
      setStocks(stocks.data.payload);      
    }

    const updateOrders = async () => {
      await axiosInstance.post('update/orders/statuses');
      getOrders();
    }

    const removeOrder = async (_id) => {
      await axiosInstance.post('remove/order', { _id });
      getOrders();
    }

    useEffect(() => {
      getOrders();
      getSites();
      getStocks();
      getNpAccounts();
    }, []);

    useEffect(() => {
      setTabOrders(orders[selectedTab])
    }, [orders])
    
    const columns = [
      {
        title: t('id'),
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 80
      },
      {
        title: t('site'),
        dataIndex: ['site', 'name'],
        key: 'site',
        render: (text, { deliveryStatus, _id }) => <Select 
          disabled={deliveryStatus === 'Removed'}
          placeholder="Sites"
          key={{_id}}
          style={{width: '100%'}}
          value={text}
          options={(sites || []).map((d) => ({
              value: d._id,
              label: d.name,
          }))} 
        />
      },
      {
        title: t('stock'),
        dataIndex: ['stock', 'name'],
        key: 'stock',
        render: (text, { deliveryStatus }) => <Select 
          disabled={deliveryStatus === 'Removed'}
          value={text}
          placeholder='Stocks'
          style={{width: '100%'}} 
          options={(stocks || []).map((d) => ({
              value: d._id,
              label: d.name,
            }))} 
        />
      },
      {
        title: t('client'),
        dataIndex: ['client', 'name'],
        key: 'client',
        render: (text, { client }) => <p>{ text }<br />{ client.phone }</p>
      },
      {
        title: t('deliveryType'),
        dataIndex: 'deliveryType',
        key: 'deliveryType',
        render: (text, { deliveryStatus, _id }) => <Select disabled={deliveryStatus === 'Removed'} value={text} style={{width: '100%'}} options={[
          {
            value: 'selfpickup',
            label: t('selfpickup')
          },
          {
            value: 'courierDelivery',
            label: t('courierDelivery')
          },
          {
            value: 'novaposhtaDelivery',
            label: t('novaposhtaDelivery')
          }
        ]} />
      },
      {
        title: t('deliveryStatus'),
        dataIndex: 'deliveryStatus',
        key: 'deliveryStatus',
        render: (text, { deliveryType, deliveryStatus, _id }) => <Select onChange={value => {changeDelvieryStatus(value, _id)}} disabled={deliveryStatus === 'Removed' || deliveryType === 'novaposhtaDelivery' } value={text} style={{width: '100%'}} options={[
          {
            label: t('all'),
            value: 'all'
          },
          {
            label: t('notConfirmed'),
            value: 'notConfirmed'
          },
          {
            label: t('onAssembly'),
            value: 'OnAssembly'
          },
          {
            label: t('awaitingDelivery'),
            value: 'AwaitingDelivery'
          },
          {
            label: t('onTheWay'),
            value: 'OnTheWay'
          },
          {
            label: t('delivered'),
            value: 'Delivered'
          },
          {
            label: t('money'),
            value: 'Money'
          },
          {
            label: t('completed'),
            value: 'Completed'
          },
          {
            label: t('removed'),
            value: 'Removed'
          },
          {
            label: t('returned'),
            value: 'Returned'
          },
          ]} />
      },
      {
        title: t('orderStatus'),
        dataIndex: 'orderStatus',
        key: 'orderStatus',
        render: (text, { deliveryStatus, _id }) => <Select disabled={deliveryStatus === 'Removed'} onChange={value => {changeOrderStatus(value, _id)}} value={text} style={{width: '100%'}} options={[
          {
            value: 'confirmed',
            label: t('confirmedStatus')
          },
          {
            value: 'notConfirmed',
            label: t('notConfirmedStatus')
          }
        ]} />
      },
      {
        title: t('paymentType'),
        dataIndex: 'paymentType',
        key: 'paymentType',
        filters: [
          {
              text: t('cash'),
              value: 'cash'
          },
          {
              text: t('card'),
              value: 'card'
          }
      ],
      onFilter: (value, record) => record.paymentType.startsWith(value),
      filterSearch: true,
        render: (text, { deliveryStatus, _id }) => <Select onChange={value => {changePaymentType(value, _id)}} disabled={deliveryStatus === 'Removed'} value={text} style={{width: '100%'}} options={[
          {
            value: 'card',
            label: t('card')
          },
          {
            value: 'cash',
            label: t('cash')
          }
        ]} />
      },
      {
        title: t('paymentStatus'),
        dataIndex: 'paymentStatus',
        key: 'paymentStatus',
        render: (text, { _id, deliveryStatus }) => <Select disabled={deliveryStatus === 'Removed'} onChange={value => {changePaymentStatus(value, _id)}} value={text} style={{width: '100%'}} options={[
          {
            value: 'paid',
            label: t('paid')
          },
          {
            value: 'notPaid',
            label: t('notPaid')
          }
        ]} />
      },
      // {
      //   title: t('npAccount'),
      //   dataIndex: ['npDelivery', 'npAccount', '_id'],
      //   key: 'npAccount',
      //   render: (text, { _id, deliveryStatus, deliveryType }) => <Select 
      //     disabled={deliveryStatus === 'Removed'}
      //     placeholder='NP Account'
      //     value={text}
      //     onChange={value => changeNpAccount(value, _id)}
      //     style={{width: '100%', display: deliveryType === 'novaposhtaDelivery' ? 'block' : 'none' }} 
      //     options={(npAccounts || []).map((d) => ({
      //         value: d._id,
      //         label: d.name,
      //       }))} 
      //   />
      // },
      // {
      //   key: t('products'),
      //   width: 45,
      //   render: (text, { products }) => 
      //     <Popover content={(
      //       <div>
      //         { products.map((item, index) => 
      //           <div key={index}>{item.product.name} | {item.quantity} pcs. | {item.price} ₺ <br /></div>
      //         )}
      //       </div>
      //     )} title="Ordered Products">
      //     <InfoCircleOutlined />
      //   </Popover>
      // },
      {
        title: t('summary'),
        dataIndex: 'summary',
        key: 'summary',
        width: 95,
        render: (text, { npDelivery }) => <p>{ text } ₺<br />{ npDelivery ? npDelivery['deliveryCost'] : 0 } ₺ 🚚</p>
      },
      {
        title: t('created'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 100,
        render: (text) => <>{moment(text).format("DD.MM.YYYY HH:mm:ss")}</>
      },
      {
        fixed: 'right',
        width: 65, 
        render: (text, { _id, id ,npDelivery, deliveryStatus }) => 
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          { !['Completed'].includes(deliveryStatus) ?
            <>
              <Button onClick={() => {navigate(`../view/order/${id}`)}} icon={<EyeOutlined />} />		
            </> 
            :
		<>
			<Button type='primary' danger onClick={() => {removeOrder(_id)}} icon={<DeleteOutlined />}/>
        		<Button onClick={() => {navigate(`../view/order/${id}`)}} icon={<EyeOutlined />} />		
		</>
         }
          </div>
        
      },
    ];


    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Tabs
            className="ordersTabs"
            onChange={orderStatusFilter}
            defaultActiveKey="all"
            items={
              [
              {
                label: `${t('all')} ${ordersCount['all']}`,
                key: 'all'
              },
              {
                label: `${t('notConfirmed')} ${ordersCount['notConfirmed']}`,
                key: 'notConfirmed'
              },
              {
                label: `${t('onAssembly')} ${ordersCount['onAssembly']}`,
                key: 'onAssembly'
              },
              {
                label: `${t('awaitingDelivery')} ${ordersCount['awaitingDelivery']}`,
                key: 'awaitingDelivery'
              },
              {
                label: `${t('onTheWay')} ${ordersCount['onTheWay']}`,
                key: 'onTheWay'
              },
              {
                label: `${t('delivered')} ${ordersCount['delivered']}`,
                key: 'delivered'
              },
              {
                label: `${t('money')} ${ordersCount['money']}`,
                key: 'money'
              },
              {
                label: `${t('completed')} ${ordersCount['completed']}`,
                key: 'completed'
              },
              {
                label: `${t('removed')} ${ordersCount['removed']}`,
                key: 'removed'
              },
              {
                label: `${t('returned')} ${ordersCount['returned']}`,
                key: 'returned'
              },
              ]}
          />
          <div style={{display: 'flex', gap: '10px', marginRight: '10px'}}>
            <Button type="primary" onClick={() => navigate('../create/order')} icon={<PlusOutlined />} />
            {/* <Button onClick={() => {updateOrders()}} icon={<SyncOutlined />} /> */}
          </div>
        </div>
        <Table 
          dataSource={tabOrders}
          columns={columns}
          bordered
          scroll={{
            x: 1300,
          }}
        />
    </>
    )
}

export default OrdersPage
